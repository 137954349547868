"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.tabBarText = exports.tabBarButton = exports.tabBar = exports.container = exports.defaultProps = void 0;

var _styledComponents = require("styled-components");

var _styledSystem = require("styled-system");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _propTypes2 = _interopRequireDefault(require("@styled-system/prop-types"));

var _Col = require("../Col");

var _Text = require("../Text");

var _styles = require("../../styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-size: 16px;\n  text-align: center;\n  ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  flex: 1;\n  height: 100%;\n  justify-content: center;\n  margin-left: 2.5px;\n  margin-right: 2.5px;\n  background-color: white;\n  border-width: 0;\n  border-bottom-width: 1px;\n  ", ";\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  flex: 0;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: ", ";\n  flex: 1;\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var defaultProps = {
  color: 'grey',
  borderColor: 'pink',
  barHeight: 'medium'
};
exports.defaultProps = defaultProps;
var container = (0, _styledComponents.css)(_templateObject(), function (_ref) {
  var selected = _ref.selected;
  return selected ? 'flex' : 'none';
}, _Col.container);
exports.container = container;
container.propTypes = _objectSpread({
  selected: _propTypes["default"].bool
}, _Col.container.propTypes);
var tabBar = (0, _styledComponents.css)(_templateObject2(), function (props) {
  var value = (0, _styles.buttonHeight)(props);
  if (value) return {
    height: value.height,
    flexBasis: value.height
  };
}, _styledSystem.space, _styledSystem.background, _styledSystem.justifyContent, _styledSystem.alignItems);
exports.tabBar = tabBar;
tabBar.propTypes = _objectSpread({}, _propTypes2["default"].buttonStyle, {}, _propTypes2["default"].space, {}, _propTypes2["default"].background, {}, _propTypes2["default"].flexbox);
var tabBarButton = (0, _styledComponents.css)(_templateObject3(), function (_ref2) {
  var borderColor = _ref2.borderColor,
      selected = _ref2.selected,
      theme = _ref2.theme;
  return {
    borderColor: selected ? theme.colors[borderColor] || borderColor : 'transparent'
  };
}, function (_ref3) {
  var borderWidth = _ref3.borderWidth;
  return {
    borderBottomWeight: borderWidth
  };
});
exports.tabBarButton = tabBarButton;
tabBarButton.propTypes = {
  borderColor: _propTypes["default"].string,
  borderWidth: _propTypes["default"].oneOf([_propTypes["default"].string, _propTypes["default"].number]),
  selected: _propTypes["default"].bool
};
var tabBarText = (0, _styledComponents.css)(_templateObject4(), _Text.container);
exports.tabBarText = tabBarText;
tabBarText.propTypes = _objectSpread({}, _Text.container.propTypes);
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _Input = _interopRequireDefault(require("../Input"));

var _Button = require("./Button");

var _default = _react["default"].forwardRef(function (_ref, ref) {
  var style = _ref.style,
      props = (0, _objectWithoutProperties2["default"])(_ref, ["style"]);
  return _react["default"].createElement(_Input["default"], (0, _extends2["default"])({
    ref: ref,
    hideErrorView: true,
    hideLabelView: true,
    style: Object.assign({
      marginTop: "-".concat(_Button.tagVerticalMargin, "px"),
      border: 'none',
      outline: 'none'
    }, style || {})
  }, props));
});

exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledSystem = require("styled-system");

var _Group = _interopRequireDefault(require("@emcasa/ui/lib/components/Dropdown/Group"));

var _Container = _interopRequireDefault(require("./Container"));

var _Button = _interopRequireDefault(require("./Button"));

var _Option = _interopRequireDefault(require("./Option"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n    z-index: ", ";\n    position: relative;\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    ", ";\n  "]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * Dropdown
 */
var Dropdown = (0, _Group["default"])({
  DropdownButton: _Button["default"],
  DropdownContainer: _Container["default"]
})(_styledComponents["default"].div(_templateObject(), function (_ref) {
  var _ref$zIndex = _ref.zIndex,
      zIndex = _ref$zIndex === void 0 ? 0 : _ref$zIndex,
      focused = _ref.focused;
  return zIndex + (focused ? 1 : 0);
}, _styledSystem.width));
var _default = Dropdown;
exports["default"] = _default;
Dropdown.displayName = 'Dropdown';
Dropdown.Option = _Option["default"];
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _default = {
  gridTypes: [],
  grid: undefined,
  getImageUrl: function getImageUrl(_ref) {
    var filename = _ref.filename;
    return "".concat(filename);
  }
};
exports["default"] = _default;
import { Fragment } from 'react';
import Slider from '@emcasa/ui-dom/components/Slider';
import View from '@emcasa/ui-dom/components/View';
import { MarkerLabel } from "../../../../../docs/components/slider.js";
import React from 'react';
export default {
  Fragment,
  Slider,
  View,
  MarkerLabel,
  React
};
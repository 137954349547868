"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gridStyle = exports.gridSize = exports.getGridOptions = exports.DEFAULT_GRID = exports.GRID_SPACING = void 0;

var _curry = _interopRequireDefault(require("lodash/curry"));

var GRID_SPACING = 1.5;
exports.GRID_SPACING = GRID_SPACING;
var DEFAULT_GRID = {
  columns: {
    desktop: 5,
    tablet: 4,
    phone: 2
  },
  styles: {}
};
exports.DEFAULT_GRID = DEFAULT_GRID;

var getGridOptions = function getGridOptions(_ref) {
  var _ref$theme$ImageUploa = _ref.theme.ImageUpload,
      gridTypes = _ref$theme$ImageUploa.gridTypes,
      gridType = _ref$theme$ImageUploa.gridType,
      grid = _ref$theme$ImageUploa.grid;
  return grid || gridTypes[gridType] || DEFAULT_GRID;
};

exports.getGridOptions = getGridOptions;
var gridSize = (0, _curry["default"])(function (breakpoint, props) {
  return "".concat(1 / getGridOptions(props).columns[breakpoint] * 100, "%");
});
exports.gridSize = gridSize;
var gridStyle = (0, _curry["default"])(function (key, props) {
  return getGridOptions(props).styles[key] || {};
});
exports.gridStyle = gridStyle;
import { Fragment } from 'react';
import View from '@emcasa/ui-dom/components/View';
import Text from '@emcasa/ui-dom/components/Text';
import Button from '@emcasa/ui-dom/components/Button';
import RadioButton from '@emcasa/ui-dom/components/RadioButton';
import Col from '@emcasa/ui-dom/components/Col';
import React from 'react';
export default {
  Fragment,
  View,
  Text,
  Button,
  RadioButton,
  Col,
  React
};
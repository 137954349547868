"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DraggableWrapper = exports.DraggableContainer = exports.DraggableContent = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _themeGet = require("@styled-system/theme-get");

var _styles = require("@emcasa/ui/lib/styles");

var _grid = require("../styles/grid");

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  box-sizing: border-box;\n  margin: ", "%;\n  ", ";\n  box-shadow: ", ";\n  border-radius: 4px;\n  background-color: ", ";\n  display: flex;\n  flex: 0 0 calc(", " - ", "%);\n  @media ", " {\n    flex: 0 0 calc(", " - ", "%);\n  }\n  @media ", " {\n    flex: 0 0 calc(", " - ", "%);\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  min-width: 100%;\n  border-radius: 4px;\n  overflow: hidden;\n  position: relative;\n  opacity: ", ";\n  cursor: ", ";\n  flex: 1;\n  padding-bottom: 64%;\n  transition: all 300ms;\n  ", "\n\n  > div.trash {\n    position: absolute;\n    top: ", "px;\n    left: ", "px;\n    cursor: pointer;\n    z-index: 2;\n    color: white;\n    svg {\n      filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.6));\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  position: absolute;\n  top: 0;\n  right: 0;\n  left: 0;\n  bottom: 0;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var DraggableContent = _styledComponents["default"].div(_templateObject());

exports.DraggableContent = DraggableContent;

var DraggableContainer = _styledComponents["default"].div.attrs((0, _grid.gridStyle)('Card'))(_templateObject2(), function (_ref) {
  var isDragging = _ref.isDragging;
  return isDragging ? 0 : 1;
}, function (_ref2) {
  var isDragging = _ref2.isDragging,
      isClickable = _ref2.isClickable;
  return isDragging ? 'grabbing' : isClickable ? 'pointer' : 'grab';
}, _styles.elevation, (0, _themeGet.themeGet)('space.2'), (0, _themeGet.themeGet)('space.3'));

exports.DraggableContainer = DraggableContainer;
DraggableContainer.defaultProps = {
  elevation: 3
};

var DraggableWrapper = _styledComponents["default"].div(_templateObject3(), _grid.GRID_SPACING, function (_ref3) {
  var isDragging = _ref3.isDragging,
      theme = _ref3.theme;
  return isDragging && {
    border: "2px dashed ".concat(theme.colors.disabled),
    margin: "calc(".concat(_grid.GRID_SPACING, "% - 2px) ").concat(_grid.GRID_SPACING, "%")
  };
}, function (_ref4) {
  var isActive = _ref4.isActive,
      theme = _ref4.theme;
  return isActive ? "0 0 1px 2px ".concat(theme.colors.pink) : 'none';
}, (0, _themeGet.themeGet)('colors.lightGrey'), (0, _grid.gridSize)('desktop'), _grid.GRID_SPACING * 2, _styles.breakpoint.down('tablet'), (0, _grid.gridSize)('tablet'), _grid.GRID_SPACING * 2, _styles.breakpoint.down('phone'), (0, _grid.gridSize)('phone'), _grid.GRID_SPACING * 2);

exports.DraggableWrapper = DraggableWrapper;
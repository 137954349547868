"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BREAKPOINTS = exports.ICON_SIZE = exports.FONT_WEIGHT = exports.FONT_SIZE = exports.BUTTON_HEIGHT = exports.LETTER_SPACING = void 0;
var LETTER_SPACING = new Map([['xnarrow', -0.82], ['narrow', -0.59], ['default', '0px'], ['wide', 0.56]]);
exports.LETTER_SPACING = LETTER_SPACING;
var BUTTON_HEIGHT = new Map([['tall', 60], ['medium', 40], ['short', 32]]);
exports.BUTTON_HEIGHT = BUTTON_HEIGHT;
var FONT_SIZE = new Map([['xsmall', 10], ['small', 14], ['default', 18], ['large', 24], ['xlarge', 32]]);
exports.FONT_SIZE = FONT_SIZE;
var FONT_WEIGHT = new Map([['default', 400], ['normal', 400], ['bold', 500]]);
exports.FONT_WEIGHT = FONT_WEIGHT;
var ICON_SIZE = new Map([['default', 20]]);
exports.ICON_SIZE = ICON_SIZE;
var BREAKPOINTS = new Map([['phone', 0], ['tablet', '480px'], ['desktop', '780px']]);
exports.BREAKPOINTS = BREAKPOINTS;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function get() {
    return _hoc["default"];
  }
});
exports.track = exports.marker = exports.container = void 0;

var _styledComponents = require("styled-components");

var _styledSystem = require("styled-system");

var _styles = require("../../styles");

var _Col = require("../Col");

var _hoc = _interopRequireDefault(require("./hoc"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  height: 2px;\n  ", ";\n  background-color: ", ";\n  ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  bottom: ", "px;\n  left: ", "px;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: ", "px;\n  height: ", "px;\n  border-radius: ", "px;\n  background-color: ", ";\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: center;\n  flex-direction: column;\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

/**
 * Component structure
 * <container>
 *   <markerContainer><marker /></markerContainer>
 *   <markerContainer><marker /></markerContainer>
 *   <track>
 *     <markerTrack />
 *     <markerTrack />
 *   </track>
 * </container>
 */
var container = (0, _styledComponents.css)(_templateObject(), _Col.container, _styledSystem.width, _styles.buttonHeight);
exports.container = container;
container.defaultProps = {
  width: '100%',
  height: 'tall'
};
var marker = (0, _styledComponents.css)(_templateObject2(), function (_ref) {
  var size = _ref.size;
  return size;
}, function (_ref2) {
  var size = _ref2.size;
  return size;
}, function (_ref3) {
  var size = _ref3.size;
  return size;
}, function (_ref4) {
  var theme = _ref4.theme,
      bg = _ref4.bg;
  return theme.colors[bg];
}, function (_ref5) {
  var focus = _ref5.focus,
      theme = _ref5.theme,
      bg = _ref5.bg;
  return focus && "box-shadow: 0 0 0 10px ".concat(theme.colors[bg], "50;");
});
exports.marker = marker;
marker.label = (0, _styledComponents.css)(_templateObject3(), function (_ref6) {
  var size = _ref6.size;
  return size;
}, function (_ref7) {
  var size = _ref7.size;
  return parseInt(size) / 2;
});
marker.defaultProps = {
  size: 16,
  bg: 'pink'
};
var track = (0, _styledComponents.css)(_templateObject4(), _styledSystem.height, function (_ref8) {
  var theme = _ref8.theme,
      bg = _ref8.bg;
  return theme.colors[bg];
}, _styledSystem.zIndex);
exports.track = track;
track.defaultProps = {
  width: '100%',
  bg: 'pink'
};
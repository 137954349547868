import Col from '@emcasa/ui-dom/components/Col';
import Text from '@emcasa/ui-dom/components/Text';
import Field from '@emcasa/ui-dom/components/Field';
import Dropdown from '@emcasa/ui-dom/components/Dropdown';
import React from 'react';
export default {
  Col,
  Text,
  Field,
  Dropdown,
  React
};
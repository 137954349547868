"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.placeholder = exports.error = exports.text = exports.container = void 0;

var _styledComponents = require("styled-components");

var _themeGet = require("@styled-system/theme-get");

var _styledSystem = require("styled-system");

var _styles = require("../../styles");

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border-radius: 4px;\n  border: 1px solid;\n  border-color: ", ";\n  padding: ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var container = (0, _styledComponents.css)(_templateObject(), function (_ref) {
  var active = _ref.active,
      disabled = _ref.disabled,
      focus = _ref.focus,
      colors = _ref.theme.colors;
  if (focus) return colors.blue;else if (active && !disabled) return colors.pink;else return colors.lightGrey;
}, function (_ref2) {
  var area = _ref2.area,
      space = _ref2.theme.space;
  return area ? "".concat(space[2], "px ").concat(space[2], "px ").concat(space[2], "px ").concat(space[2], "px") : "".concat(space[0], "px ").concat(space[2], "px ").concat(space[0], "px ").concat(space[2], "px");
}, _styles.buttonHeight, _styledSystem.border, _styledSystem.borderColor, _styledSystem.space);
exports.container = container;
container.propTypes = _objectSpread({}, _styledSystem.border.propTypes, {}, _styledSystem.borderColor.propTypes);
container.defaultProps = {
  letterSpacing: 'default',
  height: 'tall',
  fluid: true
};
var text = (0, _styledComponents.css)(_templateObject2(), function (_ref3) {
  var disabled = _ref3.disabled,
      colors = _ref3.theme.colors;
  return disabled ? colors.disabled : colors.dark;
}, _styles.defaultFontFamily, _styledSystem.color, _styles.fontSize, _styles.letterSpacing, _styledSystem.fontFamily);
exports.text = text;
text.propTypes = _objectSpread({}, _styledSystem.color.propTypes, {}, _styledSystem.fontFamily.propTypes);
text.defaultProps = {
  fontSize: 'default',
  letterSpacing: 'default'
};
var error = (0, _styledComponents.css)(_templateObject3(), (0, _themeGet.themeGet)('colors.red'), _styles.defaultFontFamily, _styles.fontSize, _styles.letterSpacing);
exports.error = error;
error.defaultProps = {
  fontSize: 'small',
  letterSpacing: 'default'
};
var placeholder = (0, _styledComponents.css)(_templateObject4(), (0, _themeGet.themeGet)('colors.disabled'));
exports.placeholder = placeholder;
placeholder.propTypes = {};
"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _utils = require("@emcasa/ui/lib/utils");

var input = _interopRequireWildcard(require("@emcasa/ui/lib/components/Input"));

var _Field = _interopRequireDefault(require("../Field"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  -webkit-font-smoothing: antialiased;\n  display: block;\n  box-sizing: border-box;\n  width: ", ";\n  outline: none;\n  :focus {\n    ", ";\n  }\n  ::placeholder {\n    ", ";\n  }\n  ", ";\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var focused = (0, _utils.withProps)({
  focus: true
});

var ForwardedInput = _react["default"].forwardRef(function (_ref, ref) {
  var area = _ref.area,
      props = (0, _objectWithoutProperties2["default"])(_ref, ["area"]);
  delete props.fluid;
  delete props.focus;
  return area ? _react["default"].createElement("textarea", (0, _extends2["default"])({
    ref: ref
  }, props)) : _react["default"].createElement("input", (0, _extends2["default"])({
    ref: ref
  }, props));
});

var TextInput = (0, _styledComponents["default"])(ForwardedInput)(_templateObject(), function (_ref2) {
  var fluid = _ref2.fluid;
  return fluid ? '100%' : 'auto';
}, focused(input.container), input.placeholder, input.container, input.text);

var Input = _react["default"].forwardRef(function (props, ref) {
  return _react["default"].createElement(_Field["default"], (0, _extends2["default"])({
    Component: TextInput,
    fowardedRef: ref
  }, props));
});

Input.propTypes = _objectSpread({}, input.container.propTypes, {}, input.text.propTypes);
Input.defaultProps = _objectSpread({}, input.container.defaultProps, {}, input.text.defaultProps);
var _default = Input;
exports["default"] = _default;
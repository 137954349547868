"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var styles = _interopRequireWildcard(require("@emcasa/ui/lib/components/Icon"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var IconComponent = (0, _styledComponents["default"])((0, styles["default"])(function (_ref) {
  var icon = _ref.icon,
      size = _ref.size,
      color = _ref.color,
      style = _ref.style,
      className = _ref.className,
      id = _ref.id,
      ref = _ref.ref,
      onClick = _ref.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref, ["icon", "size", "color", "style", "className", "id", "ref", "onClick"]);
  return _react["default"].createElement("div", {
    style: style,
    className: className,
    id: id,
    onClick: onClick
  }, _react["default"].createElement("svg", {
    ref: ref,
    height: size,
    version: "1.1",
    viewBox: "0 0 ".concat(icon.width, " ").concat(icon.height),
    x: 0,
    y: 0
  }, icon.render ? icon.render(_objectSpread({}, props, {
    color: color
  })) : _react["default"].createElement("path", (0, _extends2["default"])({
    d: icon.path,
    fill: color
  }, icon.props, props))));
}))(_templateObject(), styles.container);
IconComponent.displayName = 'Icon';
IconComponent.propTypes = _objectSpread({}, styles.container.propTypes);
IconComponent.defaultProps = _objectSpread({}, styles.container.defaultProps);
var _default = IconComponent;
exports["default"] = _default;
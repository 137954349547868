"use strict";

function _typeof(obj) { if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "elevation", {
  enumerable: true,
  get: function get() {
    return _elevation["default"];
  }
});
Object.defineProperty(exports, "breakpoint", {
  enumerable: true,
  get: function get() {
    return _breakpoint["default"];
  }
});
exports.defaultFontFamily = exports.fontWeight = exports.fontSize = exports.iconWidth = exports.iconHeight = exports.iconSize = exports.buttonHeight = exports.letterSpacing = void 0;

var styles = _interopRequireWildcard(require("styled-system"));

var _utils = require("../utils");

var _measures = require("../theme/measures");

var _elevation = _interopRequireDefault(require("./elevation"));

var _breakpoint = _interopRequireDefault(require("./breakpoint"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _getRequireWildcardCache() { if (typeof WeakMap !== "function") return null; var cache = new WeakMap(); _getRequireWildcardCache = function _getRequireWildcardCache() { return cache; }; return cache; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") { return { "default": obj }; } var cache = _getRequireWildcardCache(); if (cache && cache.has(obj)) { return cache.get(obj); } var newObj = {}; var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null; if (desc && (desc.get || desc.set)) { Object.defineProperty(newObj, key, desc); } else { newObj[key] = obj[key]; } } } newObj["default"] = obj; if (cache) { cache.set(obj, newObj); } return newObj; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

var transformUnit = function transformUnit(unit) {
  return function (n) {
    return isNaN(n) ? n : "".concat(n).concat(unit);
  };
};

var letterSpacing = (0, _utils.enumeratedStyle)({
  map: _measures.LETTER_SPACING,
  prop: 'letterSpacing',
  cssProperty: 'letterSpacing',
  key: 'letterSpacing',
  transformValue: transformUnit('px')
});
exports.letterSpacing = letterSpacing;
var buttonHeight = (0, _utils.enumeratedStyle)({
  map: _measures.BUTTON_HEIGHT,
  prop: 'height',
  cssProperty: 'height',
  key: 'buttonHeight',
  transformValue: transformUnit('px')
});
exports.buttonHeight = buttonHeight;

var iconSize = function iconSize(config) {
  return (0, _utils.enumeratedStyle)(_objectSpread({
    map: _measures.ICON_SIZE,
    prop: 'size',
    key: 'iconSize',
    transformValue: transformUnit('px')
  }, config));
};

exports.iconSize = iconSize;
var iconHeight = iconSize({
  cssProperty: 'height'
});
exports.iconHeight = iconHeight;
var iconWidth = iconSize({
  cssProperty: 'height'
});
exports.iconWidth = iconWidth;
var fontSize = (0, _utils.enumerated)(_measures.FONT_SIZE, 'fontSize')(styles.fontSize);
exports.fontSize = fontSize;
var fontWeight = (0, _utils.enumerated)(_measures.FONT_WEIGHT, 'fontWeight')(styles.fontWeight);
exports.fontWeight = fontWeight;

var defaultFontFamily = function defaultFontFamily(_ref) {
  var fontFamily = _ref.theme.fontFamily;
  return fontFamily ? "font-family: ".concat(fontFamily, ";") : null;
};

exports.defaultFontFamily = defaultFontFamily;
import { Props } from 'docz';
import GoogleMapsAutoComplete from '@emcasa/places-autocomplete/GoogleMapsAutoComplete/index.jsx';
import PlaceAutoComplete from '@emcasa/places-autocomplete/PlaceAutoComplete/index.jsx';
import AddressAutoComplete from '@emcasa/places-autocomplete/AddressAutoComplete/index.jsx';
import { GOOGLE_MAPS_API_KEY } from "../../../../../docs/config";
import React from 'react';
export default {
  Props,
  GoogleMapsAutoComplete,
  PlaceAutoComplete,
  AddressAutoComplete,
  GOOGLE_MAPS_API_KEY,
  React
};
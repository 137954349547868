import "../../../../../docs/components/filters.js";
import Filters from '@emcasa/ui-dom/components/Filters';
import Button from '@emcasa/ui-dom/components/Filters/FilterButton';
import SliderRangeFilter from '@emcasa/ui-dom/components/Filters/SliderRangeFilter';
import ButtonGroupFilter from '@emcasa/ui-dom/components/Filters/ButtonGroupFilter';
import ButtonRangeFilter from '@emcasa/ui-dom/components/Filters/ButtonRangeFilter';
import { TagsFilter, TypesFilter, PriceFilter, MaintenanceFeeFilter, PricePerAreaFilter, AreaFilter, RoomsFilter, SuitesFilter, GarageSpotsFilter, GarageTypesFilter, ConstructionYearFilter } from '@emcasa/ui-dom/components/Filters/ListingFilters';
import View from '@emcasa/ui-dom/components/View';
import tags from "../../../../../docs/components/tags.json";
import React from 'react';
export default {
  Filters,
  Button,
  SliderRangeFilter,
  ButtonGroupFilter,
  ButtonRangeFilter,
  TagsFilter,
  TypesFilter,
  PriceFilter,
  MaintenanceFeeFilter,
  PricePerAreaFilter,
  AreaFilter,
  RoomsFilter,
  SuitesFilter,
  GarageSpotsFilter,
  GarageTypesFilter,
  ConstructionYearFilter,
  View,
  tags,
  React
};
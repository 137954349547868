"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.tagHeight = exports.tagVerticalMargin = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _themeGet = require("@styled-system/theme-get");

var _styledSystem = require("styled-system");

var _styles = require("@emcasa/ui/lib/styles");

var _Col = _interopRequireDefault(require("../Col"));

var _Button = _interopRequireDefault(require("../Button"));

var _Text = _interopRequireDefault(require("../Text"));

var _Icon = _interopRequireDefault(require("../Icon"));

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  overflow: hidden;\n  display: flex;\n  margin: 0 0 ", "px ", "px;\n  margin-right: 0;\n  padding: 0 ", "px;\n  ", ";\n  ", ";\n  &:hover {\n    ", ";\n  }\n  ", ":last-of-type {\n    margin-left: ", "px;\n    margin-right: -", "px;\n  }\n  ", ";\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  z-index: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: ", "px;\n  line-height: ", "px;\n  width: 20px;\n  background-color: rgba(255, 255, 255, 0.4);\n  &:hover {\n    background-color: rgba(255, 255, 255, 0.25);\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var tagVerticalMargin = 5;
exports.tagVerticalMargin = tagVerticalMargin;

var tagHeight = function tagHeight(props) {
  return {
    height: parseInt((0, _styles.buttonHeight)(props).height) - tagVerticalMargin * 2
  };
};

exports.tagHeight = tagHeight;
var IconButton = (0, _styledComponents["default"])(function TagIconButton(_ref) {
  var className = _ref.className,
      onClick = _ref.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref, ["className", "onClick"]);
  return _react["default"].createElement("div", {
    className: className,
    onMouseDown: onClick
  }, _react["default"].createElement(_Icon["default"], (0, _extends2["default"])({
    size: 10
  }, props)));
})(_templateObject(), function (props) {
  return tagHeight(props).height - 1;
}, function (props) {
  return tagHeight(props).height - 1;
});

var stopPropagation = function stopPropagation(fun) {
  return function (e) {
    e.preventDefault();
    e.stopPropagation();
    fun(e);
  };
};

var _default = (0, _styledComponents["default"])(function TagButton(_ref2) {
  var children = _ref2.children,
      onDelete = _ref2.onDelete,
      onSelect = _ref2.onSelect,
      props = (0, _objectWithoutProperties2["default"])(_ref2, ["children", "onDelete", "onSelect"]);
  delete props.color;
  return _react["default"].createElement(_Button["default"], (0, _extends2["default"])({
    onClick: !onSelect ? undefined : stopPropagation(onSelect),
    fontSize: "small",
    type: "button"
  }, props), _react["default"].createElement(_Col["default"], null, _react["default"].createElement(_Text["default"], {
    inline: true,
    fontSize: "small",
    color: "inherit"
  }, children)), onDelete && _react["default"].createElement(IconButton, {
    name: "times",
    size: 10,
    height: props.height,
    color: props.active ? 'white' : 'pink',
    onClick: stopPropagation(onDelete)
  }));
})(_templateObject2(), tagVerticalMargin, (0, _themeGet.themeGet)('space.2'), (0, _themeGet.themeGet)('space.2'), function (_ref3) {
  var color = _ref3.color,
      active = _ref3.active;
  return color && active ? {
    backgroundColor: color,
    borderColor: color
  } : undefined;
}, function (_ref4) {
  var color = _ref4.color,
      selected = _ref4.selected;
  return color && selected ? {
    color: color,
    borderColor: color
  } : undefined;
}, function (_ref5) {
  var color = _ref5.color,
      active = _ref5.active;
  return color && active ? {
    backgroundColor: color,
    borderColor: color
  } : undefined;
}, IconButton, (0, _themeGet.themeGet)('space.2'), (0, _themeGet.themeGet)('space.2'), tagHeight, _styledSystem.space);

exports["default"] = _default;
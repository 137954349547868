"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.isMobile = exports.only = exports.down = exports.up = void 0;

var getBreakpointIndex = function getBreakpointIndex(name, theme) {
  var index = theme.breakpointNames.indexOf(name);
  return index !== -1 ? index : undefined;
};

var up = function up(name) {
  return function (_ref) {
    var theme = _ref.theme;
    var index = getBreakpointIndex(name, theme);
    var minWidth = parseInt(theme.breakpoints[index]);
    return "(min-width: ".concat(minWidth, "px)");
  };
};

exports.up = up;

var down = function down(name) {
  return function (_ref2) {
    var theme = _ref2.theme;
    var index = getBreakpointIndex(name, theme);
    var maxWidth = parseInt(theme.breakpoints[index + 1]);
    return !isNaN(maxWidth) ? "(max-width: ".concat(maxWidth - 1, "px)") : '(min-width: 0px)';
  };
};

exports.down = down;

var only = function only(name) {
  return function (_ref3) {
    var theme = _ref3.theme;
    var index = getBreakpointIndex(name, theme);
    var minWidth = parseInt(theme.breakpoints[index]);
    var maxWidth = parseInt(theme.breakpoints[index + 1]);
    if (!isNaN(maxWidth)) return "(min-width: ".concat(minWidth, "px) and (max-width: ").concat(maxWidth - 1, "px)");else return "(min-width: ".concat(minWidth, "px)");
  };
};

exports.only = only;

var isMobile = function isMobile(name) {
  return ['tablet', 'phone'].indexOf(name) !== -1;
};

exports.isMobile = isMobile;
var _default = {
  up: up,
  down: down,
  only: only,
  isMobile: isMobile
};
exports["default"] = _default;
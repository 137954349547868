import { Fragment } from 'react';
import Dropdown from '@emcasa/ui-dom/components/Dropdown';
import View from '@emcasa/ui-dom/components/View';
import Row from '@emcasa/ui-dom/components/Row';
import Text from '@emcasa/ui-dom/components/Text';
import Icon from '@emcasa/ui-dom/components/Icon';
import ItunesSearch from "../../../../../docs/components/ItunesSearch";
import React from 'react';
export default {
  Fragment,
  Dropdown,
  View,
  Row,
  Text,
  Icon,
  ItunesSearch,
  React
};
import { Fragment } from 'react';
import View from '@emcasa/ui-dom/components/View';
import Row from '@emcasa/ui-dom/components/Row';
import Col from '@emcasa/ui-dom/components/Col';
import Text from '@emcasa/ui-dom/components/Text';
import Button from '@emcasa/ui-dom/components/Button';
import React from 'react';
export default {
  Fragment,
  View,
  Row,
  Col,
  Text,
  Button,
  React
};
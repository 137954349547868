"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ButtonContainer = exports.IconButton = exports.Image = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _styledSystem = require("styled-system");

var _Icon = _interopRequireDefault(require("@emcasa/ui-dom/components/Icon"));

var _grid = require("../styles/grid");

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  z-index: 1;\n  display: flex;\n  flex-direction: row-reverse;\n  position: absolute;\n  top: 0;\n  right: 0;\n  ", ";\n  svg {\n    filter: drop-shadow(0 1px 1px rgba(0, 0, 0, 0.46));\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 40px;\n  width: 40px;\n  cursor: pointer;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  background-image: ", ";\n  background-position: center;\n  background-repeat: no-repeat;\n  background-size: cover;\n  width: 100%;\n  height: 100%;\n  padding-bottom: 64%;\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Image = _styledComponents["default"].div(_templateObject(), function (_ref) {
  var image = _ref.image,
      getImageUrl = _ref.theme.ImageUpload.getImageUrl;
  return "url(".concat(getImageUrl ? getImageUrl(image) : image.filename, ")");
}, function (_ref2) {
  var image = _ref2.image;
  return !image.isActive && {
    filter: 'grayscale(1)'
  };
});

exports.Image = Image;
var IconButton = (0, _styledComponents["default"])(function IconButton(_ref3) {
  var name = _ref3.name,
      props = (0, _objectWithoutProperties2["default"])(_ref3, ["name"]);
  return _react["default"].createElement("a", props, _react["default"].createElement(_Icon["default"], {
    color: "white",
    name: name
  }));
})(_templateObject2());
exports.IconButton = IconButton;

var ButtonContainer = _styledComponents["default"].div.attrs((0, _grid.gridStyle)('ButtonContainer'))(_templateObject3(), _styledSystem.display);

exports.ButtonContainer = ButtonContainer;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.withSliderContext = exports.Provider = exports.Consumer = void 0;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

var _React$createContext = _react["default"].createContext({}),
    Consumer = _React$createContext.Consumer,
    Provider = _React$createContext.Provider;

exports.Provider = Provider;
exports.Consumer = Consumer;

var withSliderContext = function withSliderContext(mapStateToProps) {
  return function (Target) {
    return function (props) {
      return _react["default"].createElement(Consumer, null, function (ctx) {
        return _react["default"].createElement(Target, _extends({}, props, mapStateToProps(ctx)));
      });
    };
  };
};

exports.withSliderContext = withSliderContext;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = ImageUpload;

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _UploadTarget = _interopRequireDefault(require("../UploadTarget"));

var _Images = _interopRequireDefault(require("../Images"));

var _Provider = _interopRequireDefault(require("../theme/Provider"));

var _styles = require("./styles");

function ImageUpload(_ref) {
  var showInactive = _ref.showInactive,
      theme = _ref.theme,
      images = _ref.images,
      disabled = _ref.disabled,
      queueUploads = _ref.queueUploads,
      filterFiles = _ref.filterFiles,
      onUpload = _ref.onUpload,
      onChangePosition = _ref.onChangePosition,
      onActivate = _ref.onActivate,
      onDeactivate = _ref.onDeactivate,
      onClick = _ref.onClick;
  var visibleImages = showInactive ? images : images.filter(function (img) {
    return img.isActive;
  });
  return _react["default"].createElement(_Provider["default"], {
    theme: theme
  }, _react["default"].createElement(_styles.Container, null, _react["default"].createElement(_UploadTarget["default"], {
    onUpload: onUpload,
    disabled: disabled,
    filterFiles: filterFiles,
    queueUploads: queueUploads
  }), _react["default"].createElement(_Images["default"], {
    images: visibleImages,
    onChangePosition: onChangePosition,
    onActivate: onActivate,
    onDeactivate: onDeactivate,
    onClick: onClick
  })));
}

ImageUpload.defaultProps = {
  theme: {},
  queueUploads: 3,
  filterFiles: function filterFiles(files) {
    return files;
  }
};
ImageUpload.propTypes = {
  showInactive: _propTypes["default"].bool,
  disabled: _propTypes["default"].bool,
  theme: _propTypes["default"].object.isRequired,

  /**
   * Max number of files to process in parallel.
   */
  queueUploads: _propTypes["default"].number,

  /**
   * Function called when a user selects files to upload. Receives an array
   * of File objects and should return an array of files to process.
   */
  filterFiles: _propTypes["default"].func,

  /**
   * Upload function called for each selected file, should return a Promise.
   */
  onUpload: _propTypes["default"].func.isRequired,
  onChangePosition: _propTypes["default"].func,
  onActivate: _propTypes["default"].func,
  onDeactivate: _propTypes["default"].func,
  onClick: _propTypes["default"].func
};
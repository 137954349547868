"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var button = _interopRequireWildcard(require("@emcasa/ui/lib/components/Button"));

var _Group = _interopRequireDefault(require("@emcasa/ui/lib/components/Group"));

var _Row = _interopRequireDefault(require("../Row"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  outline: none;\n  ", ";\n  cursor: ", ";\n  ", ";\n  ", ";\n  -webkit-font-smoothing: antialiased;\n  border-style: solid;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Button = _styledComponents["default"].button(_templateObject(), function (props) {
  return props.fluid && 'display: block';
}, function (props) {
  return props.disabled ? 'default' : 'pointer';
}, button.container, button.text);

Button.defaultProps = _objectSpread({}, button.container.defaultProps, {}, button.text.defaultProps);
Button.propTypes = _objectSpread({}, button.container.propTypes, {}, button.text.propTypes, {
  children: _propTypes["default"].node,
  onClick: _propTypes["default"].func
});
Button.displayName = 'Button';
Button.Group = (0, _Group["default"])(function (_ref) {
  var onSelect = _ref.onSelect,
      selected = _ref.selected,
      disabled = _ref.disabled;
  return {
    disabled: disabled,
    onClick: onSelect,
    selected: selected
  };
})(_Row["default"]);
var _default = Button;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = ButtonRangeFilter;
exports.selectStrategy = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireDefault(require("react"));

var _propTypes = _interopRequireDefault(require("prop-types"));

var _ButtonGroupFilter = _interopRequireDefault(require("./ButtonGroupFilter"));

var _FilterButton = _interopRequireDefault(require("./FilterButton"));

var selectStrategy = function selectStrategy(_ref) {
  var _ref$range = (0, _slicedToArray2["default"])(_ref.range, 2),
      _ = _ref$range[0],
      max = _ref$range[1];

  return {
    isSelected: function isSelected(range, value) {
      return range && range.min === value;
    },
    update: function update(_, value) {
      return {
        min: value,
        max: value == max ? undefined : value
      };
    }
  };
};

exports.selectStrategy = selectStrategy;

function ButtonRangeFilter(_ref2) {
  var _ref2$range = (0, _slicedToArray2["default"])(_ref2.range, 2),
      min = _ref2$range[0],
      max = _ref2$range[1],
      formatText = _ref2.formatText,
      _ref2$formatEmpty = _ref2.formatEmpty,
      formatEmpty = _ref2$formatEmpty === void 0 ? formatText : _ref2$formatEmpty,
      _ref2$formatFirst = _ref2.formatFirst,
      formatFirst = _ref2$formatFirst === void 0 ? formatText : _ref2$formatFirst,
      _ref2$formatLast = _ref2.formatLast,
      formatLast = _ref2$formatLast === void 0 ? formatText : _ref2$formatLast,
      buttonProps = _ref2.buttonProps,
      props = (0, _objectWithoutProperties2["default"])(_ref2, ["range", "formatText", "formatEmpty", "formatFirst", "formatLast", "buttonProps"]);

  return _react["default"].createElement(_ButtonGroupFilter["default"], (0, _extends2["default"])({
    strategy: selectStrategy({
      range: [min, max]
    })
  }, props), Array(max - min + 1).fill(null).map(function (_, index) {
    var value = index + min;
    var input = {
      index: index,
      value: value
    };
    return _react["default"].createElement(_FilterButton["default"], (0, _extends2["default"])({
      key: index,
      value: value
    }, buttonProps), value == 0 ? formatEmpty(input) : index == 0 ? formatFirst(input) : value == max ? formatLast(input) : formatText(input));
  }));
}

ButtonRangeFilter.selectStrategy = selectStrategy;
ButtonRangeFilter.defaultProps = {
  formatText: function formatText(_ref3) {
    var value = _ref3.value;
    return value;
  },
  buttonProps: {}
};
ButtonRangeFilter.propTypes = {
  range: _propTypes["default"].arrayOf(_propTypes["default"].number).isRequired,
  formatText: _propTypes["default"].func,
  formatEmpty: _propTypes["default"].func,
  formatFirst: _propTypes["default"].func,
  formatLast: _propTypes["default"].func,
  buttonProps: _propTypes["default"].object
};
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Dropdown = require("@emcasa/ui/lib/components/Dropdown");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  outline: none;\n  cursor: pointer;\n  ", ";\n  ", ";\n  overflow: hidden;\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  &:hover,\n  &:focus {\n    ", ";\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var DropdownOption = (0, _styledComponents["default"])(function (_ref) {
  var children = _ref.children,
      id = _ref.id,
      className = _ref.className,
      style = _ref.style,
      onSelect = _ref.onSelect;
  return _react["default"].createElement("button", {
    type: "button",
    tabIndex: 0,
    id: id,
    className: className,
    style: style,
    onMouseDown: onSelect
  }, children);
})(_templateObject(), _Dropdown.option, _Dropdown.option.text, _Dropdown.option.hover);
DropdownOption.displayName = 'DropdownOption';
DropdownOption.propTypes = _Dropdown.option.propTypes;
DropdownOption.defaultProps = _Dropdown.option.defaultProps;
var _default = DropdownOption;
exports["default"] = _default;
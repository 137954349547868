"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DESKTOP_PANEL_WIDTH = exports.MOBILE_PANEL_MIN_HEIGHT = exports.TOP_SPACING = exports.ROW_HEIGHT = exports.ROW_PADDING = exports.BUTTON_HEIGHT = exports.MAINTENANCE_FEE_STEP = exports.MAX_MAINTENANCE_FEE = exports.MIN_MAINTENANCE_FEE = exports.PRICE_PER_AREA_STEP = exports.MAX_PRICE_PER_AREA = exports.MIN_PRICE_PER_AREA = exports.PRICE_STEP = exports.MAX_PRICE = exports.MIN_PRICE = exports.MAX_AREA = exports.MIN_AREA = void 0;

var _themeGet = require("@styled-system/theme-get");

var MIN_AREA = 35;
exports.MIN_AREA = MIN_AREA;
var MAX_AREA = 500;
exports.MAX_AREA = MAX_AREA;
var MIN_PRICE = 150000;
exports.MIN_PRICE = MIN_PRICE;
var MAX_PRICE = 5000000;
exports.MAX_PRICE = MAX_PRICE;
var PRICE_STEP = 1000;
exports.PRICE_STEP = PRICE_STEP;
var MIN_PRICE_PER_AREA = 1000;
exports.MIN_PRICE_PER_AREA = MIN_PRICE_PER_AREA;
var MAX_PRICE_PER_AREA = 100000;
exports.MAX_PRICE_PER_AREA = MAX_PRICE_PER_AREA;
var PRICE_PER_AREA_STEP = 100;
exports.PRICE_PER_AREA_STEP = PRICE_PER_AREA_STEP;
var MIN_MAINTENANCE_FEE = 0;
exports.MIN_MAINTENANCE_FEE = MIN_MAINTENANCE_FEE;
var MAX_MAINTENANCE_FEE = 5000;
exports.MAX_MAINTENANCE_FEE = MAX_MAINTENANCE_FEE;
var MAINTENANCE_FEE_STEP = 100;
exports.MAINTENANCE_FEE_STEP = MAINTENANCE_FEE_STEP;
var BUTTON_HEIGHT = (0, _themeGet.themeGet)('buttonHeight.1');
exports.BUTTON_HEIGHT = BUTTON_HEIGHT;
var ROW_PADDING = (0, _themeGet.themeGet)('space.2');
exports.ROW_PADDING = ROW_PADDING;

var ROW_HEIGHT = function ROW_HEIGHT(props) {
  return BUTTON_HEIGHT(props) + ROW_PADDING(props) * 2;
};

exports.ROW_HEIGHT = ROW_HEIGHT;

var TOP_SPACING = function TOP_SPACING(props) {
  return (!props.row.visible ? 0 : props.row.expanded ? props.offset : ROW_HEIGHT(props)) + props.theme.space[4] * 2;
};

exports.TOP_SPACING = TOP_SPACING;
var MOBILE_PANEL_MIN_HEIGHT = 200;
exports.MOBILE_PANEL_MIN_HEIGHT = MOBILE_PANEL_MIN_HEIGHT;

var DESKTOP_PANEL_WIDTH = function DESKTOP_PANEL_WIDTH(_ref) {
  var width = _ref.width;
  return width || 300;
};

exports.DESKTOP_PANEL_WIDTH = DESKTOP_PANEL_WIDTH;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Spinner = exports.UploadCard = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Text = _interopRequireDefault(require("@emcasa/ui-dom/components/Text"));

var _styles = require("@emcasa/ui/lib/styles");

var _PulseLoader = _interopRequireDefault(require("react-spinners/PulseLoader"));

var _Card = _interopRequireDefault(require("../Card"));

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  & > div {\n    display: flex;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  outline: none;\n  background: white;\n  * {\n    cursor: pointer;\n  }\n  ", " {\n    margin: 0 4px;\n  }\n  @media ", " {\n    ", " {\n      display: none;\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var UploadCard = (0, _styledComponents["default"])(_Card["default"])(_templateObject(), function (_ref) {
  var isActive = _ref.isActive;
  return isActive && {
    transform: 'scale(1.05)'
  };
}, _Text["default"], _styles.breakpoint.down('tablet'), _Text["default"]);
exports.UploadCard = UploadCard;
var Spinner = (0, _styledComponents["default"])(function (_ref2) {
  var className = _ref2.className,
      style = _ref2.style,
      props = (0, _objectWithoutProperties2["default"])(_ref2, ["className", "style"]);
  return _react["default"].createElement("div", {
    className: className,
    style: style
  }, _react["default"].createElement(_PulseLoader["default"], props));
}).attrs(function (_ref3) {
  var theme = _ref3.theme;
  return {
    size: 6,
    color: theme.colors['pink']
  };
})(_templateObject2());
exports.Spinner = Spinner;
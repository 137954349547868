"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _ImageUpload = _interopRequireDefault(require("./ImageUpload"));

var _reactDnd = require("react-dnd");

var _reactDndHtml5Backend = _interopRequireDefault(require("react-dnd-html5-backend"));

var _default = (0, _reactDnd.DragDropContext)(_reactDndHtml5Backend["default"])(_ImageUpload["default"]);

exports["default"] = _default;
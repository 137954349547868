"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _pick = _interopRequireDefault(require("lodash/pick"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Group = _interopRequireDefault(require("@emcasa/ui/lib/components/Tab/Group"));

var tab = _interopRequireWildcard(require("@emcasa/ui/lib/components/Tab"));

var _Col = _interopRequireDefault(require("../Col"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2["default"])(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  outline: none;\n  cursor: pointer;\n  ", ";\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

/**
 * TabBar
 */
var TabBar = _styledComponents["default"].div(_templateObject(), tab.tabBar);

TabBar.displayName = 'TabBar';
TabBar.propTypes = tab.tabBar.propTypes;
/**
 * TabBarButton
 */

var TabBarButton = (0, _styledComponents["default"])(function (_ref) {
  var onSelect = _ref.onSelect,
      props = (0, _objectWithoutProperties2["default"])(_ref, ["onSelect"]);
  return _react["default"].createElement("button", (0, _extends2["default"])({
    onClick: onSelect
  }, props));
})(_templateObject2(), tab.tabBarButton, tab.tabBarText);
TabBarButton.displayName = 'TabBarButton';
TabBarButton.propTypes = _objectSpread({}, tab.tabBarButton.propTypes, {}, tab.tabBarText.propTypes);

TabBarButton.getProps = function (props) {
  return (0, _pick["default"])(props, Object.keys(TabBarButton.propTypes));
};
/**
 * Tab
 */


var Tab = _styledComponents["default"].div(_templateObject3(), tab.container);

var _default = Tab;
exports["default"] = _default;
Tab.displayName = 'Tab';
Tab.propTypes = tab.container.propTypes;
Tab.Group = (0, _Group["default"])({
  TabBarButton: TabBarButton,
  TabBar: TabBar
})(_Col["default"]);
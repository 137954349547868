"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _popmotion = require("popmotion");

var _reactMeasure = _interopRequireDefault(require("react-measure"));

var slider = _interopRequireWildcard(require("@emcasa/ui/lib/components/Slider"));

var _MarkerHandler = _interopRequireDefault(require("./MarkerHandler"));

var _SliderTrack = _interopRequireDefault(require("./SliderTrack"));

var _View = _interopRequireDefault(require("../View"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  position: relative;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  margin-bottom: 50%;\n  margin-left: -50%;\n  left: ", "px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var MarkerLabel = (0, _styledComponents["default"])(_View["default"])(_templateObject(), slider.marker.label, function (_ref) {
  var size = _ref.size;
  return size;
});
MarkerLabel.displayName = 'SliderMarkerLabel';
var Marker = (0, _styledComponents["default"])(function SliderMarker(_ref2) {
  var style = _ref2.style,
      size = _ref2.size,
      markerState = _ref2.markerState,
      renderLabel = _ref2.renderLabel,
      props = (0, _objectWithoutProperties2["default"])(_ref2, ["style", "size", "markerState", "renderLabel"]);
  return _react["default"].createElement(_View["default"], (0, _extends2["default"])({
    style: style
  }, props), renderLabel && _react["default"].createElement(MarkerLabel, {
    size: size
  }, renderLabel(markerState)));
})(_templateObject2(), slider.marker);
Marker.displayName = 'SliderMarker';
Marker.defaultProps = slider.marker.defaultProps;
var Container = (0, _styledComponents["default"])(function SliderContainer(_ref3) {
  var onLayout = _ref3.onLayout,
      props = (0, _objectWithoutProperties2["default"])(_ref3, ["onLayout"]);
  return _react["default"].createElement(_reactMeasure["default"], {
    onResize: function onResize(_ref4) {
      var entry = _ref4.entry;

      if (!entry) {
        return;
      }

      return onLayout({
        width: entry.width,
        height: entry.height
      });
    }
  }, function (_ref5) {
    var measureRef = _ref5.measureRef;
    return _react["default"].createElement(_View["default"], (0, _extends2["default"])({
      ref: measureRef
    }, props));
  });
})(_templateObject3(), slider.container);
Container.defaultProps = slider.container.defaultProps;
var SliderComponent = (0, slider["default"])({
  MarkerHandler: _MarkerHandler["default"],
  Marker: Marker,
  SliderTrack: _SliderTrack["default"],
  getInitialMarkerState: function getInitialMarkerState(_ref6) {
    var position = _ref6.position;
    return {
      animatedValues: {
        x: (0, _popmotion.value)(position)
      }
    };
  }
})(Container);
SliderComponent.Marker = Marker;
var _default = SliderComponent;
exports["default"] = _default;
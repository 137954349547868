"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.filterComponentName = exports.filterComponent = void 0;

var _curry = _interopRequireDefault(require("lodash/curry"));

var filterComponent = (0, _curry["default"])(function (components, property) {
  return components.find(function (component) {
    return component.types.includes(property);
  });
});
exports.filterComponent = filterComponent;
var filterComponentName = (0, _curry["default"])(function (components, property) {
  return (filterComponent(components, property) || {}).long_name;
});
exports.filterComponentName = filterComponentName;
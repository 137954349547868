import { Fragment } from 'react';
import Col from '@emcasa/ui-dom/components/Col';
import Row from '@emcasa/ui-dom/components/Row';
import Input from '@emcasa/ui-dom/components/Input';
import React from 'react';
export default {
  Fragment,
  Col,
  Row,
  Input,
  React
};
"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _isEmpty = _interopRequireDefault(require("lodash/fp/isEmpty"));

var _get = _interopRequireDefault(require("lodash/fp/get"));

var _cond = _interopRequireDefault(require("lodash/fp/cond"));

var _stubTrue = _interopRequireDefault(require("lodash/fp/stubTrue"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _styledSystem = require("styled-system");

var _TagInput = _interopRequireDefault(require("../TagInput"));

var _Filter = require("./Filter");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  height: auto;\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var TagFilterInput = (0, _styledComponents["default"])(_TagInput["default"]).attrs({
  focused: true,
  labelProps: {
    maxRows: 2
  },
  containerProps: {
    style: {
      position: 'relative',
      top: 'unset',
      flex: '0 1 auto',
      flexFlow: 'row wrap',
      border: 'none',
      paddingRight: '10px',
      overflowY: 'auto'
    }
  }
})(_templateObject(), _styledSystem.maxHeight);
var TagsFilter = (0, _styledComponents.withTheme)(function (_ref) {
  var theme = _ref.theme,
      onChangeText = _ref.onChangeText,
      props = (0, _objectWithoutProperties2["default"])(_ref, ["theme", "onChangeText"]);
  return _react["default"].createElement(_Filter.ControlledFilter, (0, _extends2["default"])({
    isEmpty: _isEmpty["default"],
    panelProps: {
      width: parseInt(theme.breakpoints[1])
    }
  }, props), function (_ref2) {
    var field = _ref2.field;
    return _react["default"].createElement(TagFilterInput, (0, _extends2["default"])({}, props, {
      selectedValue: field.currentValue || [],
      onChange: field.onChange,
      onChangeText: onChangeText
    }));
  });
});
var MAX_LABEL_LENGTH = 2;
TagsFilter.defaultProps = {
  name: 'tags',
  maxHeight: ['100%', null, null, '50vh'],
  formatLabel: (0, _cond["default"])([[_isEmpty["default"], function () {
    return 'Tags';
  }], [_stubTrue["default"], function (values) {
    return values.map((0, _get["default"])('name')).slice(0, MAX_LABEL_LENGTH).concat(values.length > MAX_LABEL_LENGTH ? "+ ".concat(values.length - MAX_LABEL_LENGTH) : []).join(', ');
  }]]),
  title: 'Tags'
};
var _default = TagsFilter;
exports["default"] = _default;
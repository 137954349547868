"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "TagsFilter", {
  enumerable: true,
  get: function get() {
    return _TagsFilter["default"];
  }
});
exports.ConstructionYearFilter = exports.TypesFilter = exports.GarageTypesFilter = exports.GarageSpotsFilter = exports.SuitesFilter = exports.RoomsFilter = exports.AreaFilter = exports.MaintenanceFeeFilter = exports.PricePerAreaFilter = exports.PriceFilter = exports.formatPrice = exports.formatNumRange = exports.formatSliderRange = exports.formatRange = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _isFunction = _interopRequireDefault(require("lodash/isFunction"));

var _isObject = _interopRequireDefault(require("lodash/isObject"));

var _isEmpty = _interopRequireDefault(require("lodash/isEmpty"));

var _identity = _interopRequireDefault(require("lodash/identity"));

var _join = _interopRequireDefault(require("lodash/fp/join"));

var _map = _interopRequireDefault(require("lodash/fp/map"));

var _flow = _interopRequireDefault(require("lodash/fp/flow"));

var _cond = _interopRequireDefault(require("lodash/fp/cond"));

var _negate = _interopRequireDefault(require("lodash/fp/negate"));

var _stubTrue = _interopRequireDefault(require("lodash/fp/stubTrue"));

var _numberAbbreviate = _interopRequireDefault(require("number-abbreviate"));

var _curry = _interopRequireDefault(require("lodash/fp/curry"));

var _constants = require("./constants");

var _ButtonGroupFilter = _interopRequireDefault(require("./ButtonGroupFilter"));

var _ButtonRangeFilter = _interopRequireDefault(require("./ButtonRangeFilter"));

var _SliderRangeFilter = _interopRequireDefault(require("./SliderRangeFilter"));

var _FilterButton = _interopRequireDefault(require("./FilterButton"));

var _TagsFilter = _interopRequireDefault(require("./TagsFilter"));

var hasValue = function hasValue(value) {
  return typeof value !== 'undefined';
};

var formatRange = function formatRange(formatOptions) {
  var formatFn = ((0, _isFunction["default"])(formatOptions) ? formatOptions : formatOptions.format) || _identity["default"];
  var fmt = Object.assign({
    format: formatFn,
    empty: formatFn,
    max: formatFn,
    min: formatFn,
    range: formatFn
  }, (0, _isObject["default"])(formatOptions) ? formatOptions : undefined);
  return (0, _cond["default"])([[function (_ref) {
    var min = _ref.min,
        max = _ref.max;
    return !min && !max;
  }, fmt.empty], [function (_ref2) {
    var min = _ref2.min,
        max = _ref2.max;
    return min == max;
  }, function (_ref3) {
    var min = _ref3.min;
    return fmt.format(min);
  }], [function (_ref4) {
    var min = _ref4.min;
    return !min;
  }, function (_ref5) {
    var max = _ref5.max;
    return fmt.max(max);
  }], [function (_ref6) {
    var max = _ref6.max;
    return !max;
  }, function (_ref7) {
    var min = _ref7.min;
    return fmt.min(min);
  }], [_stubTrue["default"], function (_ref8) {
    var min = _ref8.min,
        max = _ref8.max;
    return fmt.range(min, max);
  }]]);
};

exports.formatRange = formatRange;
var formatSliderRange = (0, _curry["default"])(function (format, range) {
  return (0, _flow["default"])(function (value) {
    if (!value) return value;
    return {
      min: value.min > range[0] ? value.min : undefined,
      max: value.max < range[1] ? value.max : undefined
    };
  }, formatRange({
    format: format,
    empty: function empty() {
      return "".concat(format(range[0]), " - ").concat(format(range[1]));
    },
    max: function max(value) {
      return "At\xE9 ".concat(format(value));
    },
    min: function min(value) {
      return "A partir de ".concat(format(value));
    },
    range: function range(min, max) {
      return "".concat(format(min), " - ").concat(format(max));
    }
  }));
});
exports.formatSliderRange = formatSliderRange;

var formatNumRange = function formatNumRange(noun) {
  var singular = noun;
  var plural = "".concat(noun, "s");
  return formatRange({
    format: function format(value) {
      return "".concat(value, " ").concat(value === 1 ? singular : plural);
    },
    empty: function empty() {
      return "Sem ".concat(plural);
    },
    min: function min(value) {
      return "".concat(value, "+ ").concat(value === 1 ? singular : plural);
    },
    max: function max(value) {
      return "At\xE9 ".concat(value, " ").concat(value === 1 ? singular : plural);
    },
    range: function range(min, max) {
      return "De ".concat(min, " a ").concat(max, " ").concat(plural);
    }
  });
};

exports.formatNumRange = formatNumRange;

var formatPrice = function formatPrice(value) {
  return "R$ ".concat((0, _numberAbbreviate["default"])(value, 2).toString().toUpperCase().replace('.', ','));
};

exports.formatPrice = formatPrice;
var formatPriceRange = formatSliderRange(formatPrice);
var formatPricePerAreaRange = formatSliderRange(function (value) {
  return "".concat(formatPrice(value), "/m\xB2");
});
var formatAreaRange = formatSliderRange(function (value) {
  return "".concat(value, " m\xB2");
});

var formatMultiSelect = function formatMultiSelect(getLabel) {
  return (0, _flow["default"])((0, _map["default"])(getLabel), (0, _join["default"])(', '));
};

var PriceFilter = function PriceFilter(_ref9) {
  var step = _ref9.step,
      props = (0, _objectWithoutProperties2["default"])(_ref9, ["step"]);
  return _react["default"].createElement(_SliderRangeFilter["default"], (0, _extends2["default"])({
    formatValue: function formatValue(value) {
      return Math.round(value / step) * step;
    },
    formatLabel: (0, _cond["default"])([[(0, _negate["default"])(hasValue), function () {
      return props.title;
    }], [_stubTrue["default"], formatPriceRange(props.range)]])
  }, props));
};

exports.PriceFilter = PriceFilter;
PriceFilter.initialValue = {
  min: _constants.MIN_PRICE,
  max: _constants.MAX_PRICE
};
PriceFilter.defaultProps = {
  name: 'price',
  title: 'Preço',
  step: _constants.PRICE_STEP,
  range: [PriceFilter.initialValue.min, PriceFilter.initialValue.max]
};

var PricePerAreaFilter = function PricePerAreaFilter(_ref10) {
  var step = _ref10.step,
      props = (0, _objectWithoutProperties2["default"])(_ref10, ["step"]);
  return _react["default"].createElement(_SliderRangeFilter["default"], (0, _extends2["default"])({
    formatValue: function formatValue(value) {
      return Math.round(value / step) * step;
    },
    formatLabel: (0, _cond["default"])([[(0, _negate["default"])(hasValue), function () {
      return props.title;
    }], [_stubTrue["default"], formatPricePerAreaRange(props.range)]])
  }, props));
};

exports.PricePerAreaFilter = PricePerAreaFilter;
PricePerAreaFilter.initialValue = {
  min: _constants.MIN_PRICE_PER_AREA,
  max: _constants.MAX_PRICE_PER_AREA
};
PricePerAreaFilter.defaultProps = {
  name: 'pricePerArea',
  title: 'Preço/m²',
  step: _constants.PRICE_PER_AREA_STEP,
  range: [PricePerAreaFilter.initialValue.min, PricePerAreaFilter.initialValue.max]
};

var MaintenanceFeeFilter = function MaintenanceFeeFilter(props) {
  return _react["default"].createElement(PriceFilter, props);
};

exports.MaintenanceFeeFilter = MaintenanceFeeFilter;
MaintenanceFeeFilter.initialValue = {
  min: _constants.MIN_MAINTENANCE_FEE,
  max: _constants.MAX_MAINTENANCE_FEE
};
MaintenanceFeeFilter.defaultProps = {
  name: 'maintenanceFee',
  title: 'Condomínio',
  step: _constants.MAINTENANCE_FEE_STEP,
  range: [MaintenanceFeeFilter.initialValue.min, MaintenanceFeeFilter.initialValue.max]
};

var AreaFilter = function AreaFilter(_ref11) {
  var props = (0, _extends2["default"])({}, _ref11);
  return _react["default"].createElement(_SliderRangeFilter["default"], (0, _extends2["default"])({
    formatValue: Math.round,
    formatLabel: (0, _cond["default"])([[(0, _negate["default"])(hasValue), function () {
      return 'Área';
    }], [_stubTrue["default"], formatAreaRange(props.range)]])
  }, props));
};

exports.AreaFilter = AreaFilter;
AreaFilter.initialValue = {
  min: _constants.MIN_AREA,
  max: _constants.MAX_AREA
};
AreaFilter.defaultProps = {
  name: 'area',
  label: 'Área',
  title: 'Área',
  range: [AreaFilter.initialValue.min, AreaFilter.initialValue.max]
};

var RoomsFilter = function RoomsFilter(_ref12) {
  var props = (0, _extends2["default"])({}, _ref12);
  return _react["default"].createElement(_ButtonRangeFilter["default"], (0, _extends2["default"])({
    formatEmpty: function formatEmpty() {
      return 'Sem quartos';
    },
    formatLast: function formatLast() {
      return '+';
    }
  }, props));
};

exports.RoomsFilter = RoomsFilter;
RoomsFilter.defaultProps = {
  name: 'rooms',
  formatLabel: (0, _cond["default"])([[(0, _negate["default"])(hasValue), function () {
    return 'Quartos';
  }], [_stubTrue["default"], formatNumRange('quarto')]]),
  title: 'Quartos',
  range: [1, 5]
};

var SuitesFilter = function SuitesFilter(_ref13) {
  var props = (0, _extends2["default"])({}, _ref13);
  return _react["default"].createElement(_ButtonRangeFilter["default"], (0, _extends2["default"])({
    formatEmpty: function formatEmpty() {
      return 'Sem suítes';
    },
    formatLast: function formatLast() {
      return '+';
    }
  }, props));
};

exports.SuitesFilter = SuitesFilter;
SuitesFilter.defaultProps = {
  name: 'suites',
  formatLabel: (0, _cond["default"])([[(0, _negate["default"])(hasValue), function () {
    return 'Suítes';
  }], [_stubTrue["default"], formatNumRange('suíte')]]),
  title: 'Suítes',
  range: [1, 5]
};

var GarageSpotsFilter = function GarageSpotsFilter(_ref14) {
  var props = (0, _extends2["default"])({}, _ref14);
  return _react["default"].createElement(_ButtonRangeFilter["default"], (0, _extends2["default"])({
    formatEmpty: function formatEmpty() {
      return 'Sem vagas';
    },
    formatLast: function formatLast() {
      return '+';
    }
  }, props));
};

exports.GarageSpotsFilter = GarageSpotsFilter;
GarageSpotsFilter.defaultProps = {
  name: 'garageSpots',
  title: 'Vagas de garagem',
  formatLabel: (0, _cond["default"])([[(0, _negate["default"])(hasValue), function () {
    return 'Vagas';
  }], [_stubTrue["default"], formatNumRange('vaga')]]),
  range: [0, 5]
};

var GarageTypesFilter = function GarageTypesFilter(_ref15) {
  var buttonProps = _ref15.buttonProps,
      options = _ref15.options,
      props = (0, _objectWithoutProperties2["default"])(_ref15, ["buttonProps", "options"]);
  return _react["default"].createElement(_ButtonGroupFilter["default"], (0, _extends2["default"])({
    strategy: "multi",
    isEmpty: _isEmpty["default"],
    formatLabel: (0, _cond["default"])([[_isEmpty["default"], function () {
      return 'Tipo de vaga';
    }], [_stubTrue["default"], formatMultiSelect(function (value) {
      return options.get(value);
    })]])
  }, props), Array.from(options).map(function (_ref16) {
    var _ref17 = (0, _slicedToArray2["default"])(_ref16, 2),
        value = _ref17[0],
        label = _ref17[1];

    return _react["default"].createElement(_FilterButton["default"], (0, _extends2["default"])({}, buttonProps, {
      key: value,
      value: value
    }), label);
  }));
};

exports.GarageTypesFilter = GarageTypesFilter;
GarageTypesFilter.defaultProps = {
  name: 'garageTypes',
  title: 'Tipo de vaga',
  buttonProps: {},
  options: new Map([['CONTRACT', 'Escritura'], ['CONDOMINIUM', 'Condomínio']])
};

var TypesFilter = function TypesFilter(_ref18) {
  var buttonProps = _ref18.buttonProps,
      props = (0, _objectWithoutProperties2["default"])(_ref18, ["buttonProps"]);
  return _react["default"].createElement(_ButtonGroupFilter["default"], (0, _extends2["default"])({
    strategy: "multi",
    isEmpty: _isEmpty["default"]
  }, props), _react["default"].createElement(_FilterButton["default"], (0, _extends2["default"])({}, buttonProps, {
    value: "Casa"
  }), "Casa"), _react["default"].createElement(_FilterButton["default"], (0, _extends2["default"])({}, buttonProps, {
    value: "Apartamento"
  }), "Apartamento"), _react["default"].createElement(_FilterButton["default"], (0, _extends2["default"])({}, buttonProps, {
    value: "Cobertura"
  }), "Cobertura"));
};

exports.TypesFilter = TypesFilter;
TypesFilter.defaultProps = {
  name: 'types',
  formatLabel: (0, _cond["default"])([[_isEmpty["default"], function () {
    return 'Tipos de imóvel';
  }], [_stubTrue["default"], (0, _join["default"])(', ')]]),
  title: 'Tipos de imóveis',
  buttonProps: {}
};

var ConstructionYearFilter = function ConstructionYearFilter(_ref19) {
  var props = (0, _extends2["default"])({}, _ref19);
  return _react["default"].createElement(_SliderRangeFilter["default"], (0, _extends2["default"])({
    formatValue: Math.round,
    formatLabel: (0, _cond["default"])([[(0, _negate["default"])(hasValue), function () {
      return 'Ano';
    }], [_stubTrue["default"], formatSliderRange(_identity["default"], props.range)]])
  }, props));
};

exports.ConstructionYearFilter = ConstructionYearFilter;
ConstructionYearFilter.initialValue = {
  min: 1900,
  max: parseInt(new Date().getFullYear())
};
ConstructionYearFilter.defaultProps = {
  name: 'constructionYear',
  label: 'Ano de construção',
  title: 'Ano de construção',
  range: [ConstructionYearFilter.initialValue.min, ConstructionYearFilter.initialValue.max]
};
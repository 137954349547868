"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.text = exports.container = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledSystem = require("styled-system");

var _styledComponents = require("styled-components");

var _styles = require("../../styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  padding: 0 20px 0 20px;\n  border-color: ", ";\n  background-color: ", ";\n  ", "\n  width: ", ";\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var buttonProps = {
  active: _propTypes["default"].bool,
  disabled: _propTypes["default"].bool,
  fluid: _propTypes["default"].bool,
  link: _propTypes["default"].bool
};
var container = (0, _styledComponents.css)(_templateObject(), function (_ref) {
  var active = _ref.active,
      selected = _ref.selected,
      disabled = _ref.disabled,
      colors = _ref.theme.colors;
  return (active || selected) && !disabled ? colors.pink : colors.lightGrey;
}, function (_ref2) {
  var active = _ref2.active,
      disabled = _ref2.disabled,
      colors = _ref2.theme.colors;
  return active && !disabled ? colors.pink : colors.white;
}, function (_ref3) {
  var link = _ref3.link;
  return link ? {
    borderRadius: 0,
    borderWidth: 0,
    backgroundColor: 'transparent'
  } : {
    borderRadius: '4px',
    borderWidth: '1px'
  };
}, function (_ref4) {
  var fluid = _ref4.fluid;
  return fluid ? '100%' : 'auto';
}, _styles.buttonHeight, _styledSystem.space, _styledSystem.flex, _styledSystem.flexDirection, _styledSystem.alignItems, _styledSystem.justifyContent, _styledSystem.borderColor, _styledSystem.backgroundColor);
exports.container = container;
container.propTypes = _objectSpread({}, buttonProps, {}, _styles.buttonHeight.propTypes, {}, _styledSystem.space.propTypes, {}, _styledSystem.flex.propTypes, {}, _styledSystem.flexDirection.propTypes, {}, _styledSystem.alignItems.propTypes, {}, _styledSystem.justifyContent.propTypes);
container.defaultProps = {
  height: 'medium',
  fluid: false
};
var text = (0, _styledComponents.css)(_templateObject2(), function (_ref5) {
  var active = _ref5.active,
      disabled = _ref5.disabled,
      link = _ref5.link,
      colors = _ref5.theme.colors;
  if (disabled) return colors.disabled;else if (link) return colors.pink;else if (active) return colors.white;else return colors.dark;
}, _styles.defaultFontFamily, _styles.letterSpacing, _styledSystem.fontFamily, _styles.fontSize, _styledSystem.color);
exports.text = text;
text.propTypes = _objectSpread({}, buttonProps, {}, _styledSystem.fontFamily.propTypes, {}, _styledSystem.color.propTypes);
text.defaultProps = {
  fontSize: 'default'
};
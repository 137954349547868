"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Background = exports.Content = exports.ExpandButton = exports.Body = exports.BodyExpander = exports.Form = exports.Title = exports.PanelButton = exports.Panel = exports.Container = void 0;

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _get = _interopRequireDefault(require("lodash/fp/get"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _reactPose = _interopRequireDefault(require("react-pose"));

var _themeGet = require("@styled-system/theme-get");

var _styledSystem = require("styled-system");

var _styles = require("@emcasa/ui/lib/styles");

var _Row = _interopRequireDefault(require("../Row"));

var _Col = _interopRequireDefault(require("../Col"));

var _Icon = _interopRequireDefault(require("../Icon"));

var _Text = _interopRequireDefault(require("../Text"));

var _Button = _interopRequireDefault(require("../Button"));

var _FilterButton = _interopRequireDefault(require("./FilterButton"));

var _constants = require("./constants");

function _templateObject10() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background: rgba(255, 255, 255, 0.5);\n\n  a.clickArea {\n    display: block;\n    cursor: pointer;\n    width: 100%;\n    height: 100%;\n  }\n\n  a.closeButton {\n    display: none;\n    cursor: pointer;\n    position: absolute;\n    top: 0;\n    right: 0;\n    padding: ", "px;\n  }\n\n  @media screen and ", " {\n    background: rgba(255, 255, 255, 1);\n\n    a.clickArea {\n      display: none;\n    }\n\n    a.closeButton {\n      display: block;\n    }\n  }\n"]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  z-index: 1;\n  position: absolute;\n  box-sizing: border-box;\n  flex: 1;\n  flex-direction: column;\n  display: ", ";\n  @media screen and ", " {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    width: 100%;\n    max-height: 100%;\n    height: calc(100vh - ", "px);\n    margin-top: ", "px;\n    padding: ", "px;\n    padding-top: 0;\n    overflow-y: auto;\n    transition: all ", ";\n  }\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  button {\n    padding: 0 ", "px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-color: ", ";\n  }\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: row;\n  flex-wrap: wrap;\n  .filterButton {\n    margin-right: ", "px;\n    margin-bottom: ", "px;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  box-sizing: border-box;\n  flex: 0 1 auto;\n  padding: ", "px 0;\n  height: ", "px;\n  min-height: ", "px;\n  overflow: hidden;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  flex: ", ";\n  display: flex;\n  justify-content: space-between;\n  flex-direction: row;\n  ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  transition: all 300ms ease-in-out;\n  display: flex;\n  align-items: center;\n  height: 0;\n  margin: 0;\n  overflow: hidden;\n  display: block;\n  @media screen and ", " {\n    height: ", "px;\n    line-height: ", "px;\n  }\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  font-size: ", "px;\n  @media screen and ", " {\n    height: ", "px;\n  }\n  @media screen and ", " {\n    color: ", ";\n    background-color: transparent !important;\n    padding: 0;\n    height: auto;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  padding: ", "px ", "px;\n  background-color: white;\n  .panelBody {\n    flex: 1;\n    flex-direction: column;\n    justify-content: center;\n    overflow: hidden;\n  }\n  .panelFooter {\n    margin-top: ", "px;\n    justify-content: space-between;\n  }\n  @media screen and ", " {\n    position: relative;\n    width: auto;\n    flex: 1 0 ", "px;\n    box-shadow: none;\n    padding-top: 0;\n  }\n  @media screen and ", " {\n    position: absolute;\n    width: ", "px;\n    border-radius: 4px;\n    border: 1px solid ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  position: relative;\n  display: inline-flex;\n  width: fit-content;\n  min-height: ", "px;\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var swiftInOut = [0.55, -0.15, 0.15, 1.45];
var transition = {
  duration: 250,
  ease: swiftInOut
};
transition.css = "".concat(transition.duration, "ms cubic-bezier(").concat(transition.ease.join(','), ")");

var Container = _styledComponents["default"].div(_templateObject(), _constants.ROW_HEIGHT, _styledSystem.width, _styledSystem.space, _styledSystem.zIndex);

exports.Container = Container;
var Panel = (0, _styledComponents["default"])(_Col["default"]).attrs({
  elevation: 2
})(_templateObject2(), (0, _themeGet.themeGet)('space.3'), (0, _themeGet.themeGet)('space.4'), (0, _themeGet.themeGet)('space.4'), _styles.breakpoint.down('tablet'), _constants.MOBILE_PANEL_MIN_HEIGHT, _styles.breakpoint.up('desktop'), _constants.DESKTOP_PANEL_WIDTH, (0, _themeGet.themeGet)('colors.lightGrey'));
exports.Panel = Panel;
var PanelButton = (0, _styledComponents["default"])(_Button["default"]).attrs(function (props) {
  return {
    type: 'button',
    link: !props.isMobile
  };
})(_templateObject3(), (0, _themeGet.themeGet)('fontSizes.1'), _styles.breakpoint.down('tablet'), (0, _themeGet.themeGet)('buttonHeight.0'), _styles.breakpoint.up('desktop'), function (_ref) {
  var active = _ref.active,
      colors = _ref.theme.colors;
  return active ? colors.pink : colors.grey;
});
exports.PanelButton = PanelButton;
var Title = (0, _styledComponents["default"])(_Text["default"])(_templateObject4(), _styles.breakpoint.down('tablet'), (0, _themeGet.themeGet)('buttonHeight.1'), (0, _themeGet.themeGet)('buttonHeight.1'));
exports.Title = Title;

var mapDimensions = function mapDimensions(fun) {
  var _ref2 = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {},
      _ref2$initialize = _ref2.initialize,
      initialize = _ref2$initialize === void 0 ? true : _ref2$initialize;

  return function (_ref3) {
    var dimensions = _ref3.dimensions;
    return fun(initialize ? dimensions.measure() : dimensions.get());
  };
};

var ROW_OFFSET_RIGHT = 60;

var rightOffset = function rightOffset(_ref4) {
  var right = _ref4.right;
  return Math.max(0, ROW_OFFSET_RIGHT - (window.innerWidth - right));
};

var Form = (0, _styledComponents["default"])(_reactPose["default"].form({
  filterOpen: {
    transition: transition,
    preTransition: function preTransition(props) {
      return props.dimensions.measure();
    },
    applyAtStart: {
      position: 'fixed',
      top: 0,
      left: mapDimensions((0, _get["default"])('left')),
      width: mapDimensions((0, _get["default"])('width')),
      y: mapDimensions((0, _get["default"])('top'))
    },
    y: function y(_ref5) {
      var theme = _ref5.theme;
      return theme.space[4];
    },
    paddingRight: mapDimensions(rightOffset)
  },
  filterClosed: {
    transition: transition,
    applyAtEnd: {
      position: '',
      top: '',
      left: '',
      width: '',
      y: 0
    },
    y: mapDimensions((0, _get["default"])('top'), {
      initialize: false
    }),
    paddingRight: 0
  }
})).attrs(function (_ref6) {
  var theme = _ref6.theme;
  return {
    theme: theme
  };
})(_templateObject5(), function (_ref7) {
  var fluid = _ref7.fluid;
  return fluid ? '1' : '0 1 auto';
}, _styledSystem.zIndex);
exports.Form = Form;

var EXPANDED_ROW_HEIGHT = function EXPANDED_ROW_HEIGHT(props) {
  return props.height + (0, _constants.ROW_PADDING)(props);
};

var BodyExpander = (0, _styledComponents["default"])(_reactPose["default"].div({
  rowOpen: {
    transition: transition,
    flip: true,
    height: EXPANDED_ROW_HEIGHT
  },
  rowClosed: {
    transition: transition,
    flip: true,
    height: _constants.ROW_HEIGHT
  }
})).attrs(function (_ref8) {
  var theme = _ref8.theme;
  return {
    theme: theme
  };
})(_templateObject6(), _constants.ROW_PADDING, _constants.ROW_HEIGHT, _constants.ROW_HEIGHT);
exports.BodyExpander = BodyExpander;
var Body = (0, _styledComponents["default"])(_Row["default"])(_templateObject7(), (0, _themeGet.themeGet)('space.2'), (0, _themeGet.themeGet)('space.2'));
exports.Body = Body;
var ExpandButton = (0, _styledComponents["default"])(function ExpandButton(_ref9) {
  var isRowExpanded = _ref9.isRowExpanded,
      onClick = _ref9.onClick,
      props = (0, _objectWithoutProperties2["default"])(_ref9, ["isRowExpanded", "onClick"]);
  return _react["default"].createElement(_Col["default"], props, _react["default"].createElement(_FilterButton["default"], {
    onClick: onClick,
    style: {}
  }, _react["default"].createElement(_Icon["default"], {
    name: isRowExpanded ? 'angle-up' : 'angle-down',
    color: "grey"
  })));
})(_templateObject8(), (0, _themeGet.themeGet)('space.2'), (0, _themeGet.themeGet)('colors.lightGrey'));
exports.ExpandButton = ExpandButton;

var Content = _styledComponents["default"].div(_templateObject9(), function (props) {
  return props.visible ? 'flex' : 'none';
}, _styles.breakpoint.down('tablet'), _constants.TOP_SPACING, _constants.TOP_SPACING, (0, _themeGet.themeGet)('space.4'), transition.css);

exports.Content = Content;

var BackgroundComponent = _react["default"].forwardRef(function (_ref10, ref) {
  var onDismiss = _ref10.onDismiss,
      style = _ref10.style,
      className = _ref10.className;
  return _react["default"].createElement("div", {
    ref: ref,
    style: style,
    className: className
  }, _react["default"].createElement("a", {
    className: "closeButton",
    onClick: onDismiss
  }, _react["default"].createElement(_Icon["default"], {
    name: "times",
    size: 22,
    color: "dark"
  })), _react["default"].createElement("a", {
    className: "clickArea",
    onClick: onDismiss
  }));
});

var Background = (0, _styledComponents["default"])((0, _reactPose["default"])(BackgroundComponent)({
  bgOpen: {
    applyAtStart: {
      display: 'block',
      pointerEvents: 'all'
    },
    opacity: 1
  },
  bgClosed: {
    applyAtEnd: {
      display: 'none',
      pointerEvents: 'none'
    },
    opacity: 0
  }
}))(_templateObject10(), (0, _themeGet.themeGet)('space.4'), _styles.breakpoint.down('tablet'));
exports.Background = Background;
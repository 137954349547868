"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var styles = _interopRequireWildcard(require("@emcasa/ui/lib/components/RadioButton"));

var _Group = _interopRequireDefault(require("@emcasa/ui/lib/components/Group"));

var _View = _interopRequireDefault(require("../View"));

function _templateObject4() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  -webkit-font-smoothing: antialiased;\n  cursor: ", ";\n  ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  box-sizing: border-box;\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  box-sizing: border-box;\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  box-sizing: border-box;\n  cursor: ", ";\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var Container = _styledComponents["default"].div(_templateObject(), function (props) {
  return props.disabled ? 'default' : 'pointer';
}, styles.container);

Container.defaultProps = styles.container.defaultProps;

var Button = _styledComponents["default"].div(_templateObject2(), styles.button);

Button.defaultProps = styles.button.defaultProps;

var CheckMark = _styledComponents["default"].div(_templateObject3(), styles.checkMark);

CheckMark.defaultProps = styles.checkMark.defaultProps;

var Label = _styledComponents["default"].label(_templateObject4(), function (props) {
  return props.disabled ? 'default' : 'pointer';
}, styles.label);

Label.defaultProps = styles.label.defaultProps;

function RadioButtonComponent(_ref) {
  var onChange = _ref.onChange,
      disabled = _ref.disabled,
      checked = _ref.checked,
      label = _ref.label,
      props = (0, _objectWithoutProperties2["default"])(_ref, ["onChange", "disabled", "checked", "label"]);
  return _react["default"].createElement(Container, (0, _extends2["default"])({
    onClick: onChange,
    disabled: disabled,
    checked: checked
  }, props), _react["default"].createElement(Button, {
    checked: checked,
    disabled: disabled,
    label: label
  }, checked && _react["default"].createElement(CheckMark, null)), label && _react["default"].createElement(Label, (0, _extends2["default"])({
    disabled: disabled
  }, props), label));
}

RadioButtonComponent.Group = (0, _Group["default"])(function (_ref2) {
  var onSelect = _ref2.onSelect,
      selected = _ref2.selected,
      disabled = _ref2.disabled;
  return {
    disabled: disabled,
    onChange: onSelect,
    checked: selected
  };
})(_View["default"]);
RadioButtonComponent.Group.displayName = 'RadioGroup';
var _default = RadioButtonComponent;
exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Button = _interopRequireDefault(require("../Button"));

var _constants = require("./constants");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  opacity: ", ";\n  transition: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var BUTTON_STYLE = function BUTTON_STYLE(_ref) {
  var theme = _ref.theme,
      style = _ref.style,
      active = _ref.active,
      selected = _ref.selected;
  if (style) return style;
  if (active || selected) return undefined;
  return {
    color: theme.colors.pink,
    borderColor: theme.colors.pink
  };
};

var FilterButton = (0, _styledComponents["default"])(function (_ref2) {
  var active = _ref2.active,
      selected = _ref2.selected,
      props = (0, _objectWithoutProperties2["default"])(_ref2, ["active", "selected"]);
  delete props.disabled;
  return _react["default"].createElement(_Button["default"], (0, _extends2["default"])({
    active: active || selected
  }, props));
}).attrs(function (props) {
  return {
    height: (0, _constants.BUTTON_HEIGHT)(props),
    style: BUTTON_STYLE(props)
  };
})(_templateObject(), function (_ref3) {
  var disabled = _ref3.disabled;
  return disabled ? 0.5 : 1;
}, ['color', 'border-color', 'opacity'].map(function (prop) {
  return "".concat(prop, " 200ms ease-in-out");
}).join());
FilterButton.defaultProps = {
  type: 'button',
  fontSize: 'small'
};
var _default = FilterButton;
exports["default"] = _default;
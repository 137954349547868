"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = elevation;

var _propTypes = _interopRequireDefault(require("prop-types"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance"); }

function _iterableToArrayLimit(arr, i) { if (!(Symbol.iterator in Object(arr) || Object.prototype.toString.call(arr) === "[object Arguments]")) { return; } var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

var shadowCss = function shadowCss(_ref) {
  var x = _ref.x,
      y = _ref.y,
      radius = _ref.radius,
      spread = _ref.spread;
  return "".concat(x, "px ").concat(y, "px ").concat(radius, "px ").concat(spread, "px");
};

var shadowLayers = function shadowLayers(_ref2) {
  var elevation = _ref2.elevation,
      _ref2$theme = _ref2.theme,
      shadows = _ref2$theme.shadows,
      shadowOpacity = _ref2$theme.shadowOpacity;
  return Object.entries(shadows[elevation]).map(function (_ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
        layer = _ref4[0],
        offset = _ref4[1];

    return "".concat(shadowCss(offset), " rgba(0,0,0,").concat(shadowOpacity[layer], ")");
  });
};

function elevation(props) {
  if (props.elevation) return "box-shadow: ".concat(shadowLayers(props).join(','), ";");
}

elevation.propTypes = {
  elevation: _propTypes["default"].number
};
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.label = exports.checkMark = exports.button = exports.container = exports.INNER_BULLET_SIZE = exports.BULLET_BORDER = exports.BULLET_SIZE = void 0;

var _styledComponents = require("styled-components");

var _styledSystem = require("styled-system");

var _propTypes = _interopRequireDefault(require("@styled-system/prop-types"));

var _Text = require("../Text");

var _styles = require("../../styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  width: ", "px;\n  height: ", "px;\n  border-radius: ", "px;\n  margin: ", "px;\n  background-color: white;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: ", "px;\n  height: ", "px;\n  border-radius: ", "px;\n  border: ", "px solid\n    ", ";\n  background-color: ", ";\n  margin-right: 14px;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border-radius: 4px;\n  border: 1px solid;\n  border-color: ", ";\n  padding: 10px;\n  display: flex;\n  align-items: center;\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var BULLET_SIZE = 18;
exports.BULLET_SIZE = BULLET_SIZE;
var BULLET_BORDER = 2;
exports.BULLET_BORDER = BULLET_BORDER;
var INNER_BULLET_SIZE = 6;
exports.INNER_BULLET_SIZE = INNER_BULLET_SIZE;
var container = (0, _styledComponents.css)(_templateObject(), function (_ref) {
  var checked = _ref.checked,
      colors = _ref.theme.colors;
  return checked ? colors.pink : colors.lightGrey;
}, _styles.buttonHeight);
exports.container = container;
container.defaultProps = {
  height: 'tall',
  fontSize: 'default',
  letterSpacing: 'default'
};
var button = (0, _styledComponents.css)(_templateObject2(), BULLET_SIZE, BULLET_SIZE, BULLET_SIZE, BULLET_BORDER, function (_ref2) {
  var checked = _ref2.checked,
      colors = _ref2.theme.colors;
  return checked ? colors.pink : colors.grey;
}, function (_ref3) {
  var checked = _ref3.checked,
      disabled = _ref3.disabled,
      colors = _ref3.theme.colors;
  if (!checked) return 'transparent';else if (disabled) return colors.grey;else return colors.pink;
});
exports.button = button;
button.propTypes = {};
var checkMarkMargin = (BULLET_SIZE - INNER_BULLET_SIZE - BULLET_BORDER * 2) / 2;
var checkMark = (0, _styledComponents.css)(_templateObject3(), INNER_BULLET_SIZE, INNER_BULLET_SIZE, INNER_BULLET_SIZE, checkMarkMargin);
exports.checkMark = checkMark;
checkMark.propTypes = {};
var label = (0, _styledComponents.css)(_templateObject4(), function (_ref4) {
  var disabled = _ref4.disabled,
      colors = _ref4.theme.colors;
  return disabled ? colors.disabled : colors.dark;
}, _Text.container, _styles.defaultFontFamily, _styles.fontSize, _styles.letterSpacing, _styledSystem.fontFamily);
exports.label = label;
label.propTypes = _objectSpread({}, _propTypes["default"].typography, {}, _styledSystem.fontFamily.propTypes);
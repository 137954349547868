"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireWildcard(require("styled-components"));

var _themeGet = require("@styled-system/theme-get");

var view = _interopRequireWildcard(require("@emcasa/ui/lib/components/View"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        position: fixed;\n        bottom: 0;\n        left: 0;\n        right: 0;\n        background-color: ", ";\n      "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n        margin-bottom: ", "px;\n      "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  @media only screen and (max-device-width: 600px) {\n    ", ";\n    ", ";\n  }\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var View = _styledComponents["default"].div(_templateObject(), function (_ref) {
  var body = _ref.body;
  return body && (0, _styledComponents.css)(_templateObject2(), (0, _themeGet.themeGet)('space.6'));
}, function (_ref2) {
  var bottom = _ref2.bottom;
  return bottom && (0, _styledComponents.css)(_templateObject3(), (0, _themeGet.themeGet)('colors.white'));
}, view.container);

var _default = View;
exports["default"] = _default;
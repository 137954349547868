"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.container = void 0;

var _styledComponents = require("styled-components");

var _styledSystem = require("styled-system");

var _Col = require("../Col");

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var container = (0, _styledComponents.css)(_templateObject(), _Col.container, _styledSystem.flexWrap, _styledSystem.flexBasis, _styledSystem.flexDirection, _styledSystem.alignItems, _styledSystem.justifyContent);
exports.container = container;
container.propTypes = _objectSpread({}, _Col.container.propTypes, {}, _styledSystem.flexWrap.propTypes, {}, _styledSystem.flexBasis.propTypes, {}, _styledSystem.flexDirection.propTypes, {}, _styledSystem.alignItems.propTypes, {}, _styledSystem.justifyContent.propTypes);
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.propTypes = exports.option = exports.button = exports.container = void 0;

var _styledComponents = require("styled-components");

var _themeGet = require("@styled-system/theme-get");

var _styledSystem = require("styled-system");

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styles = require("../../styles");

var _Row = require("../Row");

var _Col = require("../Col");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  border-color: ", ";\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  text-align: left;\n  ", ";\n  ", ";\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  flex-direction: row;\n  align-items: center;\n  margin: 0 5px 5px;\n  padding: 0 10px;\n  height: ", "px;\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  border-radius: 4px;\n  background-color: transparent;\n  ", ";\n  flex-grow: 0;\n  flex-shrink: 0;\n  flex-basis: auto;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  z-index: -1;\n  position: absolute;\n  bottom: 0;\n  left: 0;\n  right: 0;\n  width: 100%;\n  height: 10px;\n  background-color: white;\n  border-top-width: 0;\n  border-bottom-width: 0;\n  border-left-width: 1px;\n  border-right-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  flex: 1;\n  margin-right: 10px;\n  margin-left: 10px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 0 10px;\n  border-radius: 4px;\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  background-color: white;\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  flex-direction: column;\n  justify-content: flex-start;\n  align-items: stretch;\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  border-bottom-left-radius: 4px;\n  border-bottom-right-radius: 4px;\n  border-width: 1px;\n  border-style: solid;\n  border-color: ", ";\n  border-top-width: 0px;\n  padding-top: 5px;\n  background-color: white;\n  overflow-y: auto;\n  ", ";\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

/**
 * Dropdown component structure:
 *
 * <target>
 *   <button></button>
 *   <container>
 *     <option />
 *     <option />
 *   </container>
 * <target>
 */
var container = (0, _styledComponents.css)(_templateObject(), (0, _themeGet.themeGet)('colors.lightGrey'), _styledSystem.maxHeight, function (_ref) {
  var layout = _ref.layout;
  return layout;
});
exports.container = container;
container.contentContainer = (0, _styledComponents.css)(_templateObject2(), _Col.container);
container.propTypes = _objectSpread({
  layout: _propTypes["default"].object,
  focused: _propTypes["default"].bool,

  /**
   * Target element ref
   */
  target: _propTypes["default"].object,

  /**
   * Triggers dropdown focus
   */
  onDropdownFocus: _propTypes["default"].func,
  onDropdownBlur: _propTypes["default"].func
}, _styledSystem.width.propTypes, {}, _Col.container.propTypes);
var button = (0, _styledComponents.css)(_templateObject3(), function (_ref2) {
  var focused = _ref2.focused,
      theme = _ref2.theme;
  return focused ? theme.colors.blue : theme.colors.lightGrey;
}, _Row.container, _styledSystem.width, _styles.buttonHeight, _styledSystem.borderColor, _styledSystem.background);
exports.button = button;
button.textContainer = (0, _styledComponents.css)(_templateObject4());
button.text = (0, _styledComponents.css)(_templateObject5(), function (_ref3) {
  var isPlaceholder = _ref3.isPlaceholder,
      theme = _ref3.theme;
  return isPlaceholder ? theme.colors.disabled : theme.colors.dark;
}, _styles.fontSize, _styledSystem.textAlign, _styledSystem.color);
button.pseudoBackground = (0, _styledComponents.css)(_templateObject6(), (0, _themeGet.themeGet)('colors.lightGrey'));
button.propTypes = _objectSpread({
  focused: _propTypes["default"].bool,
  onFocusChange: _propTypes["default"].func,
  onLayout: _propTypes["default"].func
}, _styledSystem.width.propTypes, {}, _styledSystem.textAlign.propTypes, {}, _styledSystem.color.propTypes, {}, _Row.container.propTypes);
var option = (0, _styledComponents.css)(_templateObject7(), (0, _themeGet.themeGet)('buttonHeight.1'), function (_ref4) {
  var selected = _ref4.selected,
      theme = _ref4.theme;
  return selected ? theme.colors.pink : 'transparent';
}, _Row.container);
exports.option = option;
option.text = (0, _styledComponents.css)(_templateObject8(), _styles.fontSize, _styledSystem.color);
option.hover = (0, _styledComponents.css)(_templateObject9(), (0, _themeGet.themeGet)('colors.pink'));
option.propTypes = _objectSpread({
  selected: _propTypes["default"].bool,
  onSelect: _propTypes["default"].func
}, _styledSystem.color.propTypes, {}, _Row.container.propTypes);
option.defaultProps = {
  fontSize: 'default'
};

var propTypes = _objectSpread({
  containerProps: _propTypes["default"].shape(container.propTypes),
  blurOnChange: _propTypes["default"].bool
}, button.propTypes);

exports.propTypes = propTypes;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = ButtonGroupFilter;
exports.ButtonGroup = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _themeGet = require("@styled-system/theme-get");

var _Filter = require("./Filter");

var _Button = _interopRequireDefault(require("../Button"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  margin-left: -", "px;\n  margin-bottom: -", "px;\n  flex-wrap: wrap;\n  .filterButton {\n    margin-left: ", "px;\n    margin-bottom: ", "px;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var ButtonGroup = (0, _styledComponents["default"])(function ButtonGroup(_ref) {
  var children = _ref.children,
      style = _ref.style,
      className = _ref.className,
      strategy = _ref.strategy,
      currentValue = _ref.currentValue,
      onChange = _ref.onChange;
  return _react["default"].createElement(_Button["default"].Group, {
    style: style,
    className: className,
    strategy: strategy,
    selectedValue: currentValue,
    onChange: onChange
  }, _react["default"].Children.map(children, function (element) {
    return _react["default"].cloneElement(element, {
      className: 'filterButton',
      type: 'button'
    });
  }));
})(_templateObject(), (0, _themeGet.themeGet)('space.2'), (0, _themeGet.themeGet)('space.2'), (0, _themeGet.themeGet)('space.2'), (0, _themeGet.themeGet)('space.2'));
exports.ButtonGroup = ButtonGroup;

function ButtonGroupFilter(props) {
  return _react["default"].createElement(_Filter.ControlledFilter, props, function (_ref2) {
    var field = _ref2.field;
    return _react["default"].createElement(ButtonGroup, (0, _extends2["default"])({}, field, props));
  });
}
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Input = _interopRequireDefault(require("@emcasa/ui-dom/components/Input"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  border: none !important;\n  outline: none;\n  padding: none;\n  &:focus {\n    border: none;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var _default = (0, _styledComponents["default"])(_Input["default"]).attrs({
  hideErrorView: true,
  hideLabelView: true
})(_templateObject());

exports["default"] = _default;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.error = exports.label = exports.textContainer = exports.container = void 0;

var _propTypes = _interopRequireDefault(require("prop-types"));

var _styledComponents = require("styled-components");

var _themeGet = require("@styled-system/theme-get");

var _styles = require("../../styles");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-size: ", "px;\n  ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", "px;\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  min-height: ", "px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var container = {
  propTypes: {
    error: _propTypes["default"].node,
    label: _propTypes["default"].node,
    hideLabelView: _propTypes["default"].bool,
    hideErrorView: _propTypes["default"].bool
  },
  defaultProps: {
    hideLabelView: false,
    hideErrorView: false
  }
};
exports.container = container;
var textContainer = (0, _styledComponents.css)(_templateObject(), (0, _themeGet.themeGet)('space.5'));
exports.textContainer = textContainer;
var label = (0, _styledComponents.css)(_templateObject2(), (0, _themeGet.themeGet)('fontSizes.1'), _styles.defaultFontFamily);
exports.label = label;
var error = (0, _styledComponents.css)(_templateObject3(), (0, _themeGet.themeGet)('colors.red'), (0, _themeGet.themeGet)('fontSizes.1'), _styles.defaultFontFamily);
exports.error = error;
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _styledComponents = require("styled-components");

var _measures = require("../../theme/measures");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

var getIcon = function getIcon(props, _ref) {
  var icons = _ref.icons,
      defaultIcon = _ref.defaultIcon;
  var type = props.type,
      name = props.name;

  var findIcon = function findIcon(iconName) {
    var key = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : type;
    if (!(key in icons)) return undefined;
    var values = Object.keys(icons[key]).map(function (item) {
      return icons[key][item];
    });
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = values[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _icon = _step.value;
        if (_icon.iconName == iconName) return _icon;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator["return"] != null) {
          _iterator["return"]();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  };

  var icon = findIcon(name) || findIcon(defaultIcon, 'default');
  return {
    props: typeof icon.props === 'function' ? icon.props(props) : icon.props,
    render: icon.render,
    width: icon.icon[0],
    height: icon.icon[1],
    path: icon.icon[4]
  };
};

var getColors = function getColors(_ref2, theme) {
  var color = _ref2.color,
      stroke = _ref2.stroke;
  return {
    color: theme.colors[color] || color,
    stroke: theme.colors[stroke] || stroke
  };
};

var getSize = function getSize(_ref3) {
  var size = _ref3.size;
  return typeof size === 'string' ? _measures.ICON_SIZE.get(size) : size;
};

var _default = function _default(Target) {
  return (0, _styledComponents.withTheme)(function (_ref4) {
    var theme = _ref4.theme,
        props = _objectWithoutProperties(_ref4, ["theme"]);

    return _react["default"].createElement(Target, _extends({}, props, getColors(props, theme), {
      size: getSize(props, theme),
      icon: getIcon(props, theme)
    }));
  });
};

exports["default"] = _default;
"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _themeGet = require("@styled-system/theme-get");

var _PulseLoader = _interopRequireDefault(require("react-spinners/PulseLoader"));

var _Row = _interopRequireDefault(require("@emcasa/ui-dom/components/Row"));

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var _default = (0, _styledComponents["default"])(function Spinner(props) {
  return _react["default"].createElement(_Row["default"], {
    alignItems: "center",
    justifyContent: "center",
    p: 3
  }, _react["default"].createElement(_PulseLoader["default"], props));
}).attrs({
  size: 6,
  color: (0, _themeGet.themeGet)('colors.pink')
})(_templateObject());

exports["default"] = _default;
"use strict";

var _interopRequireWildcard = require("@babel/runtime/helpers/interopRequireWildcard");

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var field = _interopRequireWildcard(require("@emcasa/ui/lib/components/Field"));

var _View = _interopRequireDefault(require("../View"));

var _Text = _interopRequireDefault(require("../Text"));

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: block;\n  ", ";\n  ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  display: block;\n  ", ";\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var LabelText = (0, _styledComponents["default"])(_Text["default"]).attrs({
  inline: true
})(_templateObject(), field.textContainer, field.label);
var ErrorText = (0, _styledComponents["default"])(_Text["default"]).attrs({
  inline: true
})(_templateObject2(), field.textContainer, field.error);

function Field(_ref) {
  var hideLabelView = _ref.hideLabelView,
      hideErrorView = _ref.hideErrorView,
      label = _ref.label,
      error = _ref.error,
      children = _ref.children,
      Component = _ref.Component,
      fowardedRef = _ref.fowardedRef,
      props = (0, _objectWithoutProperties2["default"])(_ref, ["hideLabelView", "hideErrorView", "label", "error", "children", "Component", "fowardedRef"]);
  var refProp = {};

  if (fowardedRef) {
    refProp.ref = fowardedRef;
  }

  return _react["default"].createElement(_View["default"], null, !hideLabelView && _react["default"].createElement(LabelText, null, label), Component ? _react["default"].createElement(Component, (0, _extends2["default"])({}, refProp, props)) : children, !hideErrorView && _react["default"].createElement(ErrorText, null, error));
}

Field.propTypes = field.container.propTypes;
Field.defaultProps = field.container.defaultProps;
var _default = Field;
exports["default"] = _default;
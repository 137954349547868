import { Props } from 'docz';
import { Fragment } from 'react';
import View from '@emcasa/ui-dom/src/View';
import Map from '@emcasa/ui-dom/src/Map';
import Marker from '@emcasa/ui-dom/src/Map/Marker';
import { MultiMarkerBase } from '@emcasa/ui-dom/src/Map/MultiMarker';
import PaginatedMultiMarker from '@emcasa/ui-dom/src/Map/PaginatedMultiMarker';
import ButtonControl from '@emcasa/ui-dom/src/Map/ButtonControl';
import SelectControl from '@emcasa/ui-dom/src/Map/SelectControl';
import Icon from '@emcasa/ui-dom/src/Icon';
import PlaceSearch from "../../../../../docs/components/PlaceSearch";
import PawMarker from "../../../../../docs/components/PawMarker";
import markers from "../../../../../docs/components/markers.json";
import { GOOGLE_MAPS_API_KEY } from "../../../../../docs/config";
import React from 'react';
export default {
  Props,
  Fragment,
  View,
  Map,
  Marker,
  MultiMarkerBase,
  PaginatedMultiMarker,
  ButtonControl,
  SelectControl,
  Icon,
  PlaceSearch,
  PawMarker,
  markers,
  GOOGLE_MAPS_API_KEY,
  React
};
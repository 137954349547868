"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _styles = require("./styles");

function DraggableCard(_ref) {
  var children = _ref.children,
      isDragging = _ref.isDragging,
      isActive = _ref.isActive,
      innerRef = _ref.innerRef,
      props = (0, _objectWithoutProperties2["default"])(_ref, ["children", "isDragging", "isActive", "innerRef"]);
  return _react["default"].createElement(_styles.DraggableWrapper, (0, _extends2["default"])({
    ref: innerRef,
    isDragging: isDragging,
    isActive: isActive
  }, props), _react["default"].createElement(_styles.DraggableContainer, {
    isDragging: isDragging,
    isClickable: Boolean(props.onClick)
  }, _react["default"].createElement(_styles.DraggableContent, null, children)));
}

var _default = _react["default"].forwardRef(function (props, ref) {
  return _react["default"].createElement(DraggableCard, (0, _extends2["default"])({}, props, {
    innerRef: ref
  }));
});

exports["default"] = _default;
import { Fragment } from 'react';
import Text from '@emcasa/ui-dom/components/Text';
import View from '@emcasa/ui-dom/components/View';
import Button from '@emcasa/ui-dom/components/Button';
import React from 'react';
export default {
  Fragment,
  Text,
  View,
  Button,
  React
};
import { Fragment } from 'react';
import { breakpoint } from '@emcasa/ui/lib/styles';
import { BREAKPOINTS } from '@emcasa/ui/lib/theme/measures';
import Text from '@emcasa/ui-dom/components/Text';
import View from '@emcasa/ui-dom/components/View';
import Col from '@emcasa/ui-dom/components/Col';
import Row from '@emcasa/ui-dom/components/Row';
import { Text as ExampleText } from "../../../../../docs/helpers/breakpoint.js";
import React from 'react';
export default {
  Fragment,
  breakpoint,
  BREAKPOINTS,
  Text,
  View,
  Col,
  Row,
  ExampleText,
  React
};
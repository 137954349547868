"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _react = _interopRequireDefault(require("react"));

var _styledComponents = _interopRequireDefault(require("styled-components"));

var _Dropdown = require("@emcasa/ui/lib/components/Dropdown");

var _Icon = _interopRequireDefault(require("../Icon"));

var _Text = _interopRequireDefault(require("../Text"));

function _templateObject3() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  display: flex;\n  align-items: center;\n  z-index: 0;\n  outline: none;\n  cursor: pointer;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  box-sizing: border-box;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  ", ";\n  ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

var ButtonText = (0, _styledComponents["default"])(function (_ref) {
  var id = _ref.id,
      className = _ref.className,
      style = _ref.style,
      children = _ref.children;
  return _react["default"].createElement(_Text["default"], {
    inline: true,
    id: id,
    className: className,
    style: style
  }, children);
})(_templateObject(), _Dropdown.button.textContainer, _Dropdown.button.text);

var ButtonBackground = _styledComponents["default"].div(_templateObject2(), _Dropdown.button.pseudoBackground);

var DropdownButton = (0, _styledComponents["default"])(function (_ref2) {
  var children = _ref2.children,
      id = _ref2.id,
      className = _ref2.className,
      style = _ref2.style,
      onFocusChange = _ref2.onFocusChange,
      onClick = _ref2.onClick,
      focused = _ref2.focused,
      icon = _ref2.icon,
      type = _ref2.type,
      height = _ref2.height,
      _ref2$iconProps = _ref2.iconProps,
      iconProps = _ref2$iconProps === void 0 ? {} : _ref2$iconProps,
      props = (0, _objectWithoutProperties2["default"])(_ref2, ["children", "id", "className", "style", "onFocusChange", "onClick", "focused", "icon", "type", "height", "iconProps"]);
  var iconName = typeof icon !== 'undefined' ? icon : focused ? 'caret-up' : 'caret-down';
  return _react["default"].createElement("div", null, _react["default"].createElement("div", {
    tabIndex: 0,
    id: id,
    className: className,
    style: style,
    onMouseDown: function onMouseDown() {
      return setTimeout(onFocusChange, 0);
    },
    onClick: onClick
  }, Boolean(iconName) && _react["default"].createElement(_Icon["default"], (0, _extends2["default"])({
    name: iconName,
    type: type,
    color: "dark",
    size: 18,
    height: height
  }, iconProps, {
    style: Object.assign({
      alignSelf: 'flex-start'
    }, iconProps.style)
  })), _react["default"].createElement(ButtonText, props, children)), focused && _react["default"].createElement(ButtonBackground, null));
})(_templateObject3(), _Dropdown.button);
DropdownButton.displayName = 'DropdownButton';
DropdownButton.propTypes = _Dropdown.button.propTypes;
var _default = DropdownButton;
exports["default"] = _default;
import { Fragment } from 'react';
import TagInput from '@emcasa/ui-dom/components/TagInput';
import View from '@emcasa/ui-dom/components/View';
import Text from '@emcasa/ui-dom/components/Text';
import TagSearch from "../../../../../docs/components/TagSearch";
import tags from "../../../../../docs/components/tags.json";
import React from 'react';
export default {
  Fragment,
  TagInput,
  View,
  Text,
  TagSearch,
  tags,
  React
};